import { useState, useEffect, memo, useCallback, useContext, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box, IconButton, Toolbar, List, Typography, Divider, Stack, Button, Avatar,
  Menu, MenuItem, Badge, CssBaseline, SwipeableDrawer, useMediaQuery,
  LinearProgress
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';

import CustomListItemButton from './CustomListItemButton';

import { useNavigate, Link } from 'react-router-dom'
import { useSnackbar } from 'notistack';

import { useSelector, useDispatch } from 'react-redux';
import { newEntryModalActions } from '../../store/new-entry-modal';
import NotificationCenter from '../Notifications/NotificationCenter';
import Logo from "../../images/dalvado-logo.png";

//SEARCH
import { searchDialogActions } from '../../store/search'
import { generalActions } from "../../store/general";

// ICONS
import MenuIcon from '@mui/icons-material/Menu';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';


import { authActions } from '../../store/auth';
import { dataTableActions } from '../../store/data-table';

import StyledAvatar from '../StyledAvatar/StyledAvatar';

import "../../App.css"
import "./Navbar.css";
import { useTranslation } from "react-i18next";
import { CampaignRounded, HelpOutlineOutlined, Inventory2Outlined, RequestQuoteOutlined } from '@mui/icons-material';
import { SoundContext } from 'contexts/SoundContext';
import notificationSound from 'assets/sounds/new-notification.wav';
import { useAxios } from 'contexts/AxiosContext';
import { useUserPermissions } from 'contexts/UserPermissions';
import { isEqual } from 'lodash';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  minWidth: '70px',

  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  // marginBottom: 35

}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "white",
  color: "black",
  borderBottom: "1px solid #DDDD",
  elevation: 0,

  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,

  }),
}));




const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),

      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const BootstrapButton = styled(Button)({
  background: 'linear-gradient(to right bottom, #2399F9, #1145D5)',
  borderRadius: '100px',
  '&:hover': {
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;'
  },
})

const ProfileButton = styled(Box)({
  display: 'flex',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '#F6FAFD'
  },
})
const DrawerItems = ({ open }) => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const userPermissions = useUserPermissions();
  let dayjs = require('dayjs');

  const now = dayjs();
  const untilEndDate = dayjs(auth.endDate, 'YYYY-MM-DD').diff(now, 'day');
  const startDate = dayjs(auth.startDate, 'YYYY-MM-DD');
  const sinceStartDate = now.isAfter(startDate) ? Math.abs(now.diff(startDate, 'day')) : 0;
  const endDate = dayjs(auth.endDate, 'YYYY-MM-DD');
  const trialPeriod = endDate.diff(startDate, 'day');
  const progress = 100 * sinceStartDate / trialPeriod;
  // console.log(trialPeriod, 'trialPeriod')
  // console.log(sinceStartDate, 'sinceStartDate')

  // console.log(endDate, 'endDate')
  // console.log(progress, 'progress')
  return <>
    <div>
      <DrawerHeader />
      <List>
        <CustomListItemButton to='/' icon={<BuildCircleOutlinedIcon />} text={t('Repairs')} />
        {userPermissions?.softLockedUser ? null : <CustomListItemButton to='/viewInvoices' icon={<DescriptionOutlinedIcon />} text={t('Invoices')} />}
        {userPermissions?.softLockedUser ? null : <CustomListItemButton to='/quotes' icon={<RequestQuoteOutlined />} text={t('Quotes')} />}

        {userPermissions?.softLockedUser ? null : <CustomListItemButton to='/charts' icon={<InsertChartOutlinedRoundedIcon />} text={t('Charts')} />}
        {userPermissions?.softLockedUser ? null : <CustomListItemButton to='/inventory' icon={<Inventory2Outlined />} text={t('Inventory')} />}

      </List>
      <Divider color='#8EBBFD' />
      <List>
        {userPermissions?.softLockedUser ? null : <CustomListItemButton to='/organization' icon={<AccountBalanceOutlinedIcon />} text={t('Organization')} />}
        {userPermissions?.softLockedUser ? null : <CustomListItemButton to='/printSettings' icon={<LocalPrintshopOutlinedIcon />} text={t('Ticket Settings')} />}
      </List>
    </div>
    {!process.env.REACT_APP_COSTI_LOCK && <div>
      <List>
        {auth.subscriptionType === "trial" && progress < 100 && open ?
          <Box>
            <Stack sx={{ backgroundColor: 'rgba(0,0,0,0.25)', padding: '5px', borderRadius: '5px', p: '10px' }}>
              <Stack direction={'row'} alignItems={'center'} spacing={1} >
                <Typography variant='caption' fontWeight={800} noWrap> {untilEndDate}</Typography>
                <Typography variant='caption' noWrap sx={{ color: 'white' }}>{t('general.trialLeft')}</Typography>
              </Stack>
              <LinearProgress variant="determinate" value={progress} sx={{ mt: '5px' }} />
            </Stack>

          </Box>
          : null}
        <CustomListItemButton to='https://dalvado.supahub.com/changelog' icon={<CampaignRounded />} text={t(`navigationBar.whatsNew`)} />
        <CustomListItemButton to='https://dalvado.com/support' icon={<HelpOutlineOutlined />} text={t('navigationBar.support')} />
      </List>
    </div>}
  </>
}

const MiniDrawer = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const open = useSelector(state => state.general.sideMenu)
  const auth = useSelector((state) => state.auth);
  const axios = useAxios().instance;
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [audioContext, setAudioContext] = useState(null);
  const [soundUnlocked, setSoundUnlocked] = useState(false);
  const { playSound } = useContext(SoundContext);
  const userPermissions = useUserPermissions();
  const prevNotificationCountRef = useRef(0);
  const notificationsRef = useRef(notifications);


  let dayjs = require('dayjs');
  var relativeTime = require('dayjs/plugin/relativeTime')
  dayjs.extend(relativeTime);
  useEffect(() => {
    let isMounted = true;

    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`/users/notifications`);
        if (response.status === 200) {
          return response.data;
        }
        return [];
      } catch (error) {
        console.error(error);
        return [];
      }
    };

    fetchNotifications().then(data => {
      if (!isMounted) return;
      setNotifications(data);
      notificationsRef.current = data;

      const newNotificationCount = data.reduce((acc, val) => {
        return !val.isSeen ? acc + 1 : acc;
      }, 0);
      prevNotificationCountRef.current = newNotificationCount;
      setNotificationCount(newNotificationCount);
    }).catch(console.error);

    const intervalId = setInterval(() => {
      fetchNotifications().then(data => {
        if (!isMounted) return;
        const isDataDifferent = !isEqual(data, notificationsRef.current);
        // TODO: also mark these as seen in frontend as well as in backend so we can avoid another update
        if (isDataDifferent) {
          setNotifications(data);
          notificationsRef.current = data;

          const newNotificationCount = data.reduce((acc, val) => {
            return !val.isSeen ? acc + 1 : acc;
          }, 0);

          if (newNotificationCount > prevNotificationCountRef.current) {
            playSound(notificationSound);
          }

          prevNotificationCountRef.current = newNotificationCount;
          setNotificationCount(newNotificationCount);
        }
      }).catch(console.error);
    }, 10000);

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [axios, playSound]);


  const toggleDrawer = () => {
    dispatch(generalActions.toggleSideMenu())
  };


  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const accountOpen = Boolean(anchorEl);

  const goHome = () => {
    navigate('/');
  }
  const navigateToUserSettings = () => {
    navigate('/userSettings');
  }
  const handleModalToggle = () => {
    dispatch(newEntryModalActions.toggleModal());
  }

  // SEARCH DIALOG
  const toggleSearchDialog = () => {
    dispatch(searchDialogActions.toggleModal());
  }

  const handleLogOut = () => {
    dispatch(dataTableActions.logout())
    dispatch(authActions.logout())
    handleClose();
  }

  const handleNotificationOpen = () => {
    setNotificationOpen(true);
    const unSeenNotification = notifications.filter((notification) => {
      if (notification.wasSeen === "0") {
        return notification;
      }
    });
    setNotificationCount(0);
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };
  const greaterThanLg = useMediaQuery(theme.breakpoints.only("xl"));


  return (
    <>
      <AppBar position="fixed" elevation={0}>
        <Toolbar sx={{ minHeight: '64px' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            edge="start"
            sx={{
              marginRight: '36px',
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            <Stack direction={'row'} alignItems={'center'} gap={'10px'}>
              {!process.env.REACT_APP_COSTI_LOCK && <Box
                className='app-logo'
                component="img"
                sx={{
                  height: 40,
                  display: { xs: "none", sm: "block", md: "block", lg: "block" },
                }}
                alt="dalvado"
                src={Logo}
                onClick={goHome}
              />}
            </Stack>


          </Box>

          <Stack direction='row' spacing={2} alignItems={'center'}>
            {props.activeSubscription == false ? null : <>
              <Button
                onClick={() => navigate('/service-catalog')}
                variant='outlined'
                color='black'
                startIcon={<HandymanOutlinedIcon />}
                sx={{
                  borderRadius: '100px'
                }}>
                <Typography
                  sx={{ textTransform: 'none', color: 'darkGrey.main' }}>
                  {t("Services catalog")}</Typography>
              </Button>

              {userPermissions.softLockedUser ? null : <Button
                className='invoice-button'
                onClick={() => navigate('/invoiceCreator')}
                variant='outlined'
                startIcon={<PostAddOutlinedIcon />}
                color='black'
                sx={{
                  backgroundColor: 'transparent',
                  borderRadius: '100px',
                  display: { xs: "none", md: "none", lg: "inline-flex", xl: 'inline-flex' }
                }}>
                <Typography
                  sx={{ textTransform: 'none', color: 'darkGrey.main' }}>
                  {t("Invoice")}</Typography>
              </Button>}
              <BootstrapButton
                sx={{ display: { xs: "none", md: "inline-flex", lg: "inline-flex" } }}
                className={'new-entry-button'}
                startIcon={<AddCircleOutlineOutlinedIcon />}
                disableElevation
                variant="contained"
                color="primary"
                onClick={handleModalToggle}
              >
                {t('New Entry')}
              </BootstrapButton>
              <IconButton aria-label="new-repair-order-mobile"
                onClick={handleModalToggle}
                sx={{
                  display: { xs: "inline-flex", md: "none", lg: "none" },
                  background: 'linear-gradient(to right bottom, #2399F9, #1145D5)',
                }}>
                <AddCircleOutlineOutlinedIcon sx={{ color: 'white' }} />
              </IconButton>

              <IconButton aria-label="notifications" color='darkGrey' onClick={handleNotificationOpen}>
                <Badge badgeContent={notificationCount} color="error">
                  <NotificationsNoneOutlinedIcon />
                </Badge>
              </IconButton>
            </>}


            <ProfileButton onClick={handleClick} alignItems='center'>
              <Stack direction={'row'} alignItems={'center'}>
                <StyledAvatar name={auth.userName} userId={auth.userId} alt={auth.userName} sx={{ width: 35, height: 35, mr: 2 }} src="/static/images/avatar/1.jpg" />
                <Stack direction={'row'} alignItems={'center'} sx={{ display: { xs: "none", md: "inline-flex", lg: "inline-flex" } }}>
                  <Stack>
                    <Typography color='darkGrey.main' fontSize='0.75rem' lineHeight={1.2}>{auth.organizationName}</Typography>
                    <Typography fontSize='0.75rem' fontWeight={600} lineHeight={1.2}>{auth.userName}</Typography>
                  </Stack>
                  <KeyboardArrowDownOutlinedIcon />
                </Stack>
              </Stack>
            </ProfileButton>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={accountOpen}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}

            >
              {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
              <MenuItem onClick={navigateToUserSettings}>{t("My account")}</MenuItem>
              {userPermissions?.softLockedUser ? null : <MenuItem sx={{ display: { xs: "inherit", md: "none", lg: "none", xl: 'none' } }} onClick={() => navigate('/invoiceCreator')}>{t("Invoice")}</MenuItem>}
              <MenuItem onClick={handleLogOut}>{t("Logout")}</MenuItem>
            </Menu>
          </Stack>

        </Toolbar>
      </AppBar>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Box sx={{ display: { xs: "none", md: "none", lg: "none", xl: 'block' } }}>
          {/* FULL SCREEN SIDE MENU */}
          <Drawer PaperProps={{
            sx: {
              backgroundColor: "#002864",
              color: "white",
              px: '15px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }
          }} variant='permanent' open={open}>
            <DrawerItems open={open} />
          </Drawer>
        </Box>

        <SwipeableDrawer
          PaperProps={{
            sx: {
              backgroundColor: "#002864",
              color: "white",
              px: "15px",
            },
          }}
          open={greaterThanLg ? false : open}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}

          sx={{
            display: { xs: "block", md: "block", lg: "block", xl: 'none' },
          }}
        >
          <DrawerItems open={greaterThanLg ? false : open} />
        </SwipeableDrawer>
        {props.activeSubscription == false ? null : <NotificationCenter
          notifications={notifications}
          open={notificationOpen}
          onClose={handleNotificationClose}
        />}
        <div className='navPusher'>
          <Box component="main">
            <Box className={'docMainWrapper wrapper'}>
              {props.children}
            </Box>
          </Box>
        </div>
      </Box >
    </>

  );
}





export default memo(MiniDrawer);
