import { useEffect, useState } from "react";

import { Typography, Box, Stack, TableContainer, TablePagination, Table, TableHead, TableRow, TableCell, TableBody, Button, Grid, FormControl, InputLabel, Select, MenuItem, Avatar, Divider, Chip, LinearProgress, } from "@mui/material";
import { useTranslation } from "react-i18next";
import CalendarButton from "common/CalendarButton";
import { useAxios } from "contexts/AxiosContext";
import { use } from "i18next";
import { ArrowForward, Check, CheckBox, CheckCircle, CheckCircleOutline, Circle, Close, GraphicEqTwoTone, GroupOutlined, HandymanTwoTone, HighlightOff, LeaderboardTwoTone, MemoryTwoTone, NavigateNext, PaymentsTwoTone, SignalCellularAltTwoTone, Style, TimelineRounded, UTurnLeft, UnfoldMoreRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useCurrencyFormatter } from "hooks/useCurrencyFormatter";
import { useSelector } from "react-redux";
import BoxContainer from "common/BoxContainer/BoxContainer";
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { ResponsivePie } from "@nivo/pie";
import { is } from "react-date-range/dist/locale";
import { green, pink } from '@mui/material/colors';
import NewRepairsLineChart from "./NewRepairsLineChart";
import { ResponsiveBar } from '@nivo/bar';
import ConsoleHelper from "utils/ConsoleHelper";

const RepairsDoneTable = () => {
    let dayjs = require('dayjs')
    const { formatCurrency } = useCurrencyFormatter();
    const [page, setPage] = useState(0);
    const [newRepairs, setNewRepairs] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [items, setItems] = useState([]);
    const [servicesTotal, setServicesTotal] = useState(0);
    const [refundsTotal, setRefundsTotal] = useState(0);
    const [partsTotal, setPartsTotal] = useState(0);
    const [customers, setCustomers] = useState([])
    const [resolutionCountsArray, setResolutionCountsArray] = useState([]);
    const [paidState, setPaidState] = useState('none');
    const [isFetching, setIsFetching] = useState(false);
    const { t } = useTranslation();
    const axios = useAxios().instance;
    const [selectionRange, setSelectionRange] = useState({
        startDate: dayjs().startOf('month').toDate(),
        endDate: dayjs().endOf('day').toDate(),
    });
    const [popularBrands, setPopularBrands] = useState([]);
    const [resolutionTypeId, setResolutionTypeId] = useState(null);
    const [totalResolutions, setTotalResolutions] = useState(0);
    const currency = useSelector((state) => state.auth.currency);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    useEffect(() => {
        fetchRepairs();
        setPage(0);
    }, [selectionRange, paidState, resolutionTypeId]);


    const fetchRepairs = async () => {
        try {
            setIsFetching(true);

            const startDate = dayjs(selectionRange.startDate).format('YYYY-MM-DD');
            const endDate = dayjs(selectionRange.endDate).format('YYYY-MM-DD');

            const [repairsDoneResponse, newRepairsResponse, pouparBrandsReponse] = await Promise.all([
                axios.get('/reports/repair-orders/done', {
                    params: {
                        startDate: startDate,
                        endDate: endDate,
                        paidState: paidState,
                        resolutionId: resolutionTypeId,
                    }
                }),
                axios.get('/reports/repair-orders/new', {
                    params: {
                        startDate: startDate,
                        endDate: endDate,
                    }
                }),
                axios.get('/reports/repair-orders/popular-brands', {
                    params: {
                        startDate: startDate,
                        endDate: endDate,
                        paidState: paidState,
                        resolutionId: resolutionTypeId,
                    }
                })
            ]);

            if (repairsDoneResponse.status === 200) {
                const data = repairsDoneResponse.data;
                // ConsoleHelper(repairsDoneResponse, "repairsDone");
            
                // Initialize the transformed data object with a row identifier
                const transformedData = { row: "customer" };
            
                // Access the array of customer repair counts
                let customersData = data.customerRepairCountsArray;
                const keys = [];
            
                customersData = customersData.slice(0,10);
            
                // Iterate over each item in the customers data array
                customersData.forEach(item => {            
                    // Map customer names to their repair counts in the transformedData object
                    transformedData[`${item.customerId}.${item.customerName}`] = item.repairCount;
            
                    // Optionally add the customer name to the keys array if needed
                    keys.push(`${item.customerId}.${item.customerName}`);  // Fixed from item.customer to item.customerName
                });
                        
                // Set the transformed data into the customers state
                setCustomers({ data: [transformedData], keys: keys });
                setItems(data.repairOrders);
                setServicesTotal(data.servicesTotal);
                setRefundsTotal(data.refundsTotal);
                setPartsTotal(data.partsTotal);
                let updatedResolutionCountsArray = data.resolutionCountsArray.map(item => {
                    let color = null;
                    if (item.id === "Repaired") {
                        color = "rgba(27,109,230,0.7)";
                    }
                    if (item.id === "Declined") {
                        color = "rgba(255,30,75,1)";
                    }
                    if (item.id === "Irreparable") {
                        color = "rgba(236,77,150,1)";
                    }
                    if (item.id === "Irreproducible") {
                        color = "rgba(170,180,140,1)";
                    }
                    if (item.id === "Warranty") {
                        color = "rgba(0,40,100,1)";
                    }
                    return { ...item, color: color, id: t(item.id) };
                });

                setResolutionCountsArray(updatedResolutionCountsArray);
                let total = 0;
                updatedResolutionCountsArray.forEach(item => {
                    total += item.value;
                });
                setTotalResolutions(total);
            }

            if (newRepairsResponse.status === 200) {
                // ConsoleHelper(newRepairsResponse)
                setNewRepairs(newRepairsResponse.data);
            }

            if (pouparBrandsReponse.status === 200) {
                const transformedData = { row: "brand" };
                const popularBrandsData = pouparBrandsReponse.data;
                const keys = [];

                popularBrandsData.forEach(item => {
                    transformedData[item.brand] = item.brandCount;
                    keys.push(item.brand);
                });

                setPopularBrands({ data: [transformedData], keys: keys });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsFetching(false);
        }
    }

    const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
        let total = 0;
        dataWithArc.forEach(datum => {
            total += datum.data.value;
        });

        return (
            <text
                x={centerX}
                y={centerY}
                textAnchor="middle"
                dominantBaseline="middle"
                style={{
                    fontSize: '22px',
                    fontWeight: '600',
                    fill: 'black',
                }}
            >
                {total}
            </text>
        )
    }

    const handlePieResolutionClick = (data) => {
        if (data.data) {
            // ConsoleHelper(data.data.resolutionTypeId)
            setResolutionTypeId(data.data.resolutionTypeId);
        }
    }


    return (
        <Box>
            {/* Show bar loading while fetching */}
            <Typography variant='body'>{t("charts.repairsDone.pageDescription")}</Typography>
            <Divider sx={{ my: '10px' }} />
            <LinearProgress sx={{ visibility: isFetching ? 'visible' : 'hidden', my: '10px' }} />
            <Stack direction={'row'} gap={2} mb={2}>
                <CalendarButton selectionRange={selectionRange} setSelectionRange={setSelectionRange} />
                <FormControl sx={{ width: '150px' }} size="small" variant='filled'>
                    <InputLabel id="demo-simple-select-label">{t("charts.paymentState")}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Paid state"
                        value={paidState}
                        variant="filled"
                        onChange={(e) => setPaidState(e.target.value)}
                    >
                        <MenuItem value={'none'}>-</MenuItem>
                        <MenuItem value={'yes'}>{t("general.Yes")}</MenuItem>
                        <MenuItem value={'no'}>{t("general.No")}</MenuItem>
                    </Select>
                </FormControl>
                {resolutionTypeId && <Chip label={t(resolutionTypeId)} onDelete={() => setResolutionTypeId(null)} />}
            </Stack>

            <Stack direction={'row'} alignItems={'flex-start'} flexWrap={'wrap'} gap={2}>
                <Stack gap={2} width={'300px'}>
                    <BoxContainer >
                        <Stack direction={'row'} gap={2}>
                            <Stack flex={1}>
                                <Typography variant='body'>{t("charts.servicesTotal")}</Typography>
                                <Typography variant='h5'>{formatCurrency(servicesTotal.toFixed(2), currency)}</Typography>
                            </Stack>
                            <Stack width={'45px'} height={'45px'} backgroundColor={'#e3f2fd'} borderRadius={'50px'} alignItems={'center'} justifyContent={'center'}>
                                <HandymanTwoTone color="primary" />
                            </Stack>
                        </Stack>
                    </BoxContainer>
                    <BoxContainer >
                        <Stack direction={'row'} gap={2}>
                            <Stack flex={1}>
                                <Typography variant='body'>{t("charts.partsTotal")}</Typography>
                                <Typography variant='h5'>-{formatCurrency(partsTotal.toFixed(2), currency)}</Typography>
                            </Stack>
                            <Stack width={'45px'} height={'45px'} backgroundColor={'#ffebee'} borderRadius={'50px'} alignItems={'center'} justifyContent={'center'}>
                                <MemoryTwoTone color="red" />
                            </Stack>
                        </Stack>
                    </BoxContainer>
                    <BoxContainer >
                        <Stack direction={'row'} gap={2}>
                            <Stack flex={1}>
                                <Typography variant='body'>{t("charts.refundsTotal")}</Typography>
                                <Typography variant='h5'>{formatCurrency(refundsTotal.toFixed(2), currency)}</Typography>
                            </Stack>
                            <Stack width={'45px'} height={'45px'} backgroundColor={'#ffebee'} borderRadius={'50px'} alignItems={'center'} justifyContent={'center'}>
                                <UTurnLeft color="red" sx={{ transform: 'rotate(-90deg)' }} />
                            </Stack>
                        </Stack>
                    </BoxContainer>
                    <BoxContainer >
                        <Stack direction={'row'} gap={2}>
                            <Stack flex={1}>
                                <Typography variant='body'>{t("charts.totalProfit")}</Typography>
                                <Typography variant='h5'>{formatCurrency((servicesTotal - partsTotal + refundsTotal).toFixed(2), currency)}</Typography>

                                <Stack direction={'row'} alignItems={'center'} gap={1}>
                                    <TimelineRounded color="accepted" />
                                    <Typography variant='body2' noWrap> {t("charts.averageProfit")}</Typography>
                                    <Typography variant='body2' sx={{ color: 'accepted.main' }} noWrap>
                                        {items.length > 0 ? formatCurrency(((servicesTotal - partsTotal + refundsTotal) / items.length).toFixed(2), currency) : formatCurrency(0, currency)}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack width={'45px'} height={'45px'} backgroundColor={'lightGreen.main'} borderRadius={'50px'} alignItems={'center'} justifyContent={'center'}>
                                <PaymentsTwoTone color="accepted" />
                            </Stack>
                        </Stack>
                    </BoxContainer>

                </Stack>
                <Stack gap={2}>
                    <BoxContainer >
                        <Stack direction={'row'}>
                            <Box sx={{ height: '262px', width: '455px' }}>
                                {resolutionCountsArray.length > 0 ?
                                    <ResponsivePie
                                        data={resolutionCountsArray}
                                        margin={{ top: 5, right: 100, bottom: 5, left: 5 }}
                                        enableArcLinkLabels={false}
                                        innerRadius={0.5}
                                        padAngle={0.7}
                                        cornerRadius={3}
                                        colors={d => {
                                            return d.data.color
                                        }}
                                        borderWidth={1}
                                        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                        radialLabelsSkipAngle={10}
                                        radialLabelsTextColor="#333333"
                                        radialLabelsLinkColor={{ from: 'color' }}
                                        sliceLabelsSkipAngle={10}
                                        sliceLabelsTextColor="#333333"
                                        animate={false}
                                        motionStiffness={90}
                                        motionDamping={15}
                                        onClick={handlePieResolutionClick} // Handle the click event here

                                        layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
                                        tooltip={({ datum }) => {
                                            const percentage = ((datum.value / totalResolutions) * 100).toFixed(2);
                                            return <Stack sx={{
                                                backgroundColor: 'white', py: '5px', px: '10px',
                                                border: '1px solid', borderColor: 'lightGrey.main',
                                                borderRadius: '5px',
                                                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
                                            }}>
                                                <Stack direction={'row'} alignItems={'center'} gap={1}>
                                                    <Circle sx={{ color: datum.color, fontSize: '18px' }} />
                                                    <Typography variant="body">
                                                        {datum.id}: <strong>{datum.value} ({percentage}%)</strong>
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        }}
                                        legends={[
                                            {
                                                anchor: 'right',
                                                direction: 'column',
                                                justify: false,
                                                translateX: 90,
                                                translateY: 0,
                                                itemsSpacing: 5,
                                                itemWidth: 100,
                                                itemHeight: 18,
                                                itemTextColor: '#999',
                                                itemDirection: 'left-to-right',
                                                itemOpacity: 1,
                                                symbolSize: 18,
                                                symbolShape: 'circle',
                                            }
                                        ]}
                                    /> :
                                    <Stack justifyContent={'center'} alignItems={'center'} flex={1} height={"100%"} bgcolor="rgba(0, 0, 0, 0.03)" borderRadius={'10px'}>
                                        <Typography variant='h3'>{t("charts.resolutions")}</Typography>
                                        <Typography variant='body'>{t("charts.noDataToShow")}</Typography>
                                    </Stack>}
                            </Box>
                            {/* {resolutionCountsArray.forEach((item) => {

                        })} */}
                        </Stack>
                    </BoxContainer>
                    <BoxContainer >
                        <Stack direction={'row'} gap={2}>
                            <Stack flex={1}>
                                {popularBrands.data ?
                                    <>
                                        <Typography variant='body'>{t("charts.popularBrands")}</Typography>
                                        <Box sx={{ height: '52px' }}>
                                            <ResponsiveBar
                                                data={popularBrands.data}
                                                keys={popularBrands.keys}
                                                indexBy="row"
                                                margin={{ top: 0, right: 5, bottom: 0, left: 0 }}
                                                padding={0.3}
                                                layout="horizontal"
                                                valueScale={{ type: 'linear' }}
                                                indexScale={{ type: 'band', round: true }}
                                                colors={{ scheme: 'nivo' }}
                                                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                axisLeft={null}
                                                axisTop={null}
                                                axisRight={null}
                                                axisBottom={null}
                                                labelSkipHeight={12}
                                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                animate={true}
                                                motionStiffness={90}
                                                motionDamping={15}
                                                labelSkipWidth={20}
                                                tooltip={({ id, value, color }) => (
                                                    < Stack sx={{
                                                        backgroundColor: 'white', py: '5px', px: '10px',
                                                        border: '1px solid', borderColor: 'lightGrey.main',
                                                        borderRadius: '5px',
                                                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
                                                    }}>
                                                        <Stack direction={'row'} alignItems={'center'} gap={1}>
                                                            <Circle sx={{ color: color, fontSize: '18px' }} />
                                                            <Typography variant="body">
                                                                <>{id}: <strong>{value}</strong></>
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>

                                                )}
                                            />
                                        </Box>
                                    </> :
                                    <Stack justifyContent={'center'} alignItems={'center'} flex={1} height={"100%"} bgcolor="rgba(0, 0, 0, 0.03)" borderRadius={'10px'}>
                                        <Typography variant='h3'>{t("charts.popularBrands")}</Typography>
                                        <Typography variant='body'>{t("charts.noDataToShow")}</Typography>
                                    </Stack>}
                            </Stack>
                        </Stack>
                    </BoxContainer>
                </Stack>
                <Stack gap={2}>
                    <BoxContainer >
                        <Stack direction={'row'}>
                            <Box sx={{ height: '262px', width: '455px' }}>
                                {newRepairs.length > 0 ? <>
                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                        <Typography variant='body'>{t("charts.newRepairs")}</Typography>
                                        <Typography variant='body'>Total: {newRepairs.length}</Typography>
                                    </Stack>
                                    <NewRepairsLineChart repairs={newRepairs} />
                                </>
                                    :
                                    <Stack justifyContent={'center'} alignItems={'center'} flex={1} height={"100%"} bgcolor="rgba(0, 0, 0, 0.03)" borderRadius={'10px'}>
                                        <Typography variant='h3'>{t("charts.newRepairs")}</Typography>
                                        <Typography variant='body'>{t("charts.noDataToShow")}</Typography>
                                    </Stack>}
                            </Box>
                        </Stack>
                    </BoxContainer>

                    <BoxContainer >
                        <Stack direction={'row'} gap={2}>
                            <Stack flex={1}>
                                {customers.data ?
                                    <>
                                        <Typography variant='body'>{t("charts.popularCustomers")}</Typography>
                                        <Box sx={{ height: '52px' }}>
                                            <ResponsiveBar
                                                data={customers.data}
                                                keys={customers.keys}
                                                indexBy="row"
                                                margin={{ top: 0, right: 5, bottom: 0, left: 0 }}
                                                padding={0.3}
                                                layout="horizontal"
                                                valueScale={{ type: 'linear' }}
                                                indexScale={{ type: 'band', round: true }}
                                                colors={{ scheme: 'nivo' }}
                                                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                axisLeft={null}
                                                axisTop={null}
                                                axisRight={null}
                                                axisBottom={null}
                                                labelSkipHeight={12}
                                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                                animate={true}
                                                motionStiffness={90}
                                                motionDamping={15}
                                                labelSkipWidth={20}
                                                tooltip={({ id, value, color }) => (
                                                    < Stack sx={{
                                                        backgroundColor: 'white', py: '5px', px: '10px',
                                                        border: '1px solid', borderColor: 'lightGrey.main',
                                                        borderRadius: '5px',
                                                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
                                                    }}>
                                                        <Stack direction={'row'} alignItems={'center'} gap={1}>
                                                            <Circle sx={{ color: color, fontSize: '18px' }} />
                                                            <Typography variant="body">
                                                                <>{id.split('.')[1]}: <strong>{value}</strong></>
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>

                                                )}
                                            />
                                        </Box>
                                    </> :
                                    <Stack justifyContent={'center'} alignItems={'center'} flex={1} height={"100%"} bgcolor="rgba(0, 0, 0, 0.03)" borderRadius={'10px'}>
                                        <Typography variant='h3'>{t("charts.popularCustomers")}</Typography>
                                        <Typography variant='body'>{t("charts.noDataToShow")}</Typography>
                                    </Stack>}
                            </Stack>
                        </Stack>
                    </BoxContainer>

                </Stack>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} py={'10px'} spacing={1}>
                <Box flex={1}>
                    {/* <CalendarButton selectionRange={selectionRange} setSelectionRange={setSelectionRange} /> */}
                </Box>
                <TablePagination
                    rowsPerPageOptions={[15, 30, 60]}
                    component="div"
                    count={items.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("Rows per page")}
                />
            </Stack>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {/* <TableCell style={{ width: '2%' }}></TableCell> */}
                            {/* <TableCell style={{ width: '150px' }}>{t("Created at")}</TableCell> */}
                            <TableCell style={{ width: '150px' }}>{t("charts.resolutionAt")}</TableCell>
                            <TableCell style={{ width: '200px' }} align="left">{t("ID")}</TableCell>
                            <TableCell align="left">{t("Customer")}</TableCell>
                            <TableCell align="left">{t("Repair")}</TableCell>
                            <TableCell align="right">{t("Paid")}</TableCell>
                            <TableCell align="right">{t("Services")}</TableCell>
                            <TableCell align="right">{t("Parts")}</TableCell>
                            <TableCell align="right">{t("Total")}</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!isFetching && items?.length == 0 && <TableRow>
                            <TableCell colSpan={8} align="center">
                                <Typography variant='body2'>{t("table.nothingWasFound")}</Typography>
                            </TableCell>
                        </TableRow>}
                        {!isFetching && items?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <StyledTableRow
                                key={row.id}
                            >
                                <StyledTableCell component="th" scope="row">
                                    {dayjs(row.resolutionAt).format('DD.MM.YYYY HH:mm')}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <Button component={Link} to={`/editentry/${row.organizationCounter}`} target="_blank" size="small" endIcon={<ArrowForward />}>
                                        {row.outsourcedFrom ?
                                            `${row.outsourcedFrom.organization.id}#${row.outsourcedFrom.organizationCounter}`
                                            :
                                            `${row.organizationId}#${row.organizationCounter}`
                                        }
                                    </Button>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {row.outsourcedFrom ?
                                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                            <GroupOutlined />
                                            <Typography variant={"body2"}>
                                                {row.outsourcedFrom.organization.name}
                                            </Typography>
                                        </Stack> : null}
                                    {row.customer?.name}
                                </StyledTableCell>
                                <StyledTableCell align="left" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{`${row.brand} ${row.model}`}</StyledTableCell>
                                <StyledTableCell align="right">{row.paidState == "yes" ?

                                    <CheckCircleOutline style={{ color: green[500] }} />
                                    :

                                    <HighlightOff style={{ color: 'red' }} />
                                }</StyledTableCell>
                                <StyledTableCell align="right"> {formatCurrency(parseFloat(row.serviceTotal) + parseFloat(row.refundsTotal), currency)}</StyledTableCell>
                                <StyledTableCell align="right">{formatCurrency(row.partsTotal, currency)}</StyledTableCell>
                                <StyledTableCell align="right">{formatCurrency(row.total, currency)}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[15, 30, 60]}
                    component="div"
                    count={items?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("Rows per page")}

                />
            </TableContainer>
        </Box >
    )
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default RepairsDoneTable;   