import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter, HashRouter } from 'react-router-dom';
import store from './store/index';
import { injectStore } from "./axios-config";
import "./i18n";
import { AxiosProvider } from './contexts/AxiosContext'; // Import AxiosProvider
import { SoundProvider } from "contexts/SoundContext";
injectStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <AxiosProvider>
          <SoundProvider>
            <App />
          </SoundProvider>
        </AxiosProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode >,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
