import * as React from 'react';
import { Box } from '@mui/system';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import DeviceImage from '../DeviceImage/DeviceImage';
import { Stack, Tooltip, Typography } from '@mui/material';

//  ICON
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Pattern from '@mui/icons-material/Pattern';
import PatternPopover from 'components/NewEntryStepper/PatternPopover';

const DeviceCell = ({ rowData }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePassword = () => {
        if (rowData.password && !rowData.isPasswordPattern)
            return (
                <Box width={'100%'}>
                    <Stack color={'darkGrey.main'} direction={'row'} alignItems={'center'} justifyContent={'center'}>
                        <LockOutlinedIcon sx={{ fontSize: 'inherit' }} />
                        <Typography fontSize={'13px'} fontWeight={'500'} noWrap>{rowData.password}</Typography>
                    </Stack>
                </Box>
            )
        if (rowData.password && rowData.isPasswordPattern)
            return (
                <>
                    <Box width={'100%'}>
                        <Stack
                            onMouseEnter={(e) => {
                                if (rowData.isPasswordPattern) {
                                    setAnchorEl(e.currentTarget);
                                }
                            }}
                            onMouseLeave={() => {
                                setAnchorEl(null);
                            }}
                            color={'darkGrey.main'}
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'center'}>
                            <Pattern sx={{ fontSize: 'inherit' }} />
                            <Typography fontSize={'13px'} fontWeight={'500'} noWrap>{rowData.password}</Typography>
                        </Stack>
                    </Box>
                    <PatternPopover
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        value={rowData.password}
                        valueSetUsingPattern={rowData.isPasswordPattern} />
                </>
            )
    }
    return (
        <Stack alignItems={'center'} justifyContent={'center'} height={'100%'} width={'100%'}>
            <Stack alignItems={'center'} width={'100%'}>
                <Stack alignItems={'center'} width={'100%'}>
                    <Typography color='darkGrey.main'
                        sx={{
                            minWidth: '150px',
                            maxWidth: '150px',
                            textAlign: 'center', display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                        }} whiteSpace={'break-spaces'}>{rowData.deviceCategory?.name}</Typography>
                    <DeviceImage brand={rowData.brand} />
                    <Tooltip enterDelay={20} title={`${rowData.brand} ${rowData.model}`}>
                        <Box width={'100%'}>
                            <Typography textAlign='center' noWrap>{rowData.brand} {rowData.model}</Typography>

                            {/* <Typography sx={{
                        minWidth: '150px',
                        maxWidth: '150px',
                        textAlign: 'center',
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        color:'black',
                        fontSize:'14px'
                    }} whiteSpace={'break-spaces'}>{rowData.brand} {rowData.model}</Typography> */}
                        </Box>
                    </Tooltip>
                    {handlePassword()}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default DeviceCell;