import React, { useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";
import Badge from "@mui/material/Badge";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import axios from "../../axios-config";
import { useNavigate } from "react-router-dom";

function Notification({ notification, isRead, id, title, body, avatar, handleNotificationClick, handleClose }) {

  let navigate = useNavigate();

  const [notificationState, setNotificationState] = useState(isRead);
  let dayjs = require('dayjs');

  const markNotificationAsRead = () => {
    setNotificationState(true);
    axios.patch('users/notifications/markAsRead', { notificationIds: [id] })
  }
  const handleInternNotificationClick = () => {
    if (handleNotificationClick) {
      handleNotificationClick();
    } else {
      if (!notification.repairOrder) return;
      navigate(`/editEntry/${notification.repairOrder.organizationCounter}`, { replace: true });
      handleClose();
    }

  }

  return (
    <div>
      <ListItem
        button
        onClick={() => {
          markNotificationAsRead();
          handleInternNotificationClick();
        }}
      >
        <ListItemAvatar>
          <Badge
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            overlap="circular"
            invisible={notificationState}
            color="error"
            variant="dot"
          >
            {avatar}
          </Badge>
        </ListItemAvatar>
        <ListItemText
          primary={
            title && (
              <Typography sx={{ fontWeight: 500 }} component={"span"}>
                {title}
              </Typography>
            )
          }
          secondary={
            <>
              {body && (
                <Typography component={"span"} sx={{
                  color: 'darkGrey.main',
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 3,
                }}>
                  {body}
                </Typography>
              )}
              {notification?.createdAt && (
                dayjs(notification.createdAt).format('DD.MM.YYYY HH:mm')
              )}
            </>
          }
        />
      </ListItem>
      <Divider variant="inset" />
    </div >
  );
}

export default Notification;
