import * as React from 'react';
import {
    Stack, Box,
    TextField, Divider, Typography,
    Button, IconButton, Popover, InputAdornment, Grid, Skeleton,
} from '@mui/material';
import { useState } from 'react';
import axios from "../../axios-config";
import { useSnackbar } from 'notistack';
import DeviceImage from '../../components/DeviceImage/DeviceImage';
import CategorySearch from '../../components/NewEntryStepper/CategorySearch';
import ReactiveTextField from '../../components/ReactiveTextField/ReactiveTextField';
import Section from '../../components/Section/Section';
import PartnerSelect from '../../components/PartnerSelect';


//ICONS
import EditIcon from '@mui/icons-material/ModeEdit';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { FaceOutlined, GroupOutlined, LocationCityOutlined, DocumentScannerOutlined } from '@mui/icons-material';


import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { borderColor } from '@mui/system';
import Comments from './Comments/Comments';
import CustomersSearch from '../../components/NewEntryStepper/CustomersSearch';
import { getCurrencySymbol } from '../../utils/currency';
import PasswordTextField from 'components/NewEntryStepper/PasswordTextField';
import { set } from 'lodash';

const OrderEditGeneral = ({ repairOrder, setRepairOrder, editClient, setEditClient }) => {

    const currency = useSelector((state) => state.auth.currency);

    const { t } = useTranslation();

    const [editDevice, setEditDevice] = useState(false);
    const [editDeviceData, setEditDeviceData] = useState({});
    const [customerLoading, setCustomerLoading] = useState(false);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [categoryAnchor, setCategoryAnchor] = React.useState(null);
    const categoryOpen = Boolean(categoryAnchor);

    const openCategoryPopover = async (event) => {
        setCategoryAnchor(event.currentTarget);
    }

    const openDeviceEdit = () => {
        setEditDeviceData({ brand: repairOrder.brand, model: repairOrder.model });
        setEditDevice(true);
    }
    const handleDeviceInput = (key) => (event) => {
        setEditDeviceData((prevState) => {
            return { ...prevState, [key]: event.target.value }
        })
    }

    const handleClientSetState = async (customer) => {
        try {
            let data = {};
            if (customer && customer.id) {
                data.customerId = customer.id;
            } else {
                data = null;
            }
            // console.log(customer.id);
            const res = await axios.patch(`/repair-orders/${repairOrder.id}/customer`, data);
            // console.log(res);
        } catch (err) {
            console.log(err);
        }
        setRepairOrder(prevState => {
            return { ...prevState, customer: customer }
        })
    }


    const onCategoryUpdate = async (payload) => {
        try {

            setCategoryAnchor(null);
            if (payload !== null && payload.id === repairOrder.deviceCategoryId) {
                return;
            }
            const response = await axios.patch(`/repair-orders/${repairOrder.organizationCounter}/category`, payload);

            if (!response.status === 200) {
                enqueueSnackbar(`Something went wrong!`, { variant: "error" });
                return;
            }
            enqueueSnackbar(`Category updated!`, { variant: "success" });
            setRepairOrder((prevState) => {
                return { ...prevState, deviceCategory: response.data.deviceCategory, deviceCategoryId: response.data.deviceCategoryId }
            });
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }
    const handleDeviceEdit = async () => {
        try {
            if (repairOrder.brand === editDeviceData.brand && repairOrder.model === editDeviceData.model) {
                setEditDevice(false);
                return;
            }
            const response = await axios.patch(`repair-orders/${repairOrder.organizationCounter}/device`,
                {
                    brand: editDeviceData.brand,
                    model: editDeviceData.model
                })
            if (response.status !== 200) {
                throw response.status;
            }
            setRepairOrder((prevState) => {
                return { ...prevState, brand: response.data.brand, model: response.data.model }
            })
            enqueueSnackbar(`Device updated succesfully!`, { variant: "success" });
            setEditDevice(false);
        }
        catch (err) {
            console.log(err);
            enqueueSnackbar(`Something went wrong`, { variant: "error" });
        }
    }

    const device = () => {
        if (editDevice)
            return <Stack width={'50%'} spacing={2}>
                <TextField
                    sx={{ backgroundColor: '#F1F9FD' }}
                    InputLabelProps={{ style: { fontWeight: 700 } }}
                    fullWidth
                    label="Brand"
                    value={editDevice === 0 ? repairOrder.brand ? repairOrder.brand : '' : editDeviceData.brand ? editDeviceData.brand : ''} onChange={handleDeviceInput('brand')}
                />
                <TextField
                    sx={{ backgroundColor: '#F1F9FD' }}
                    InputLabelProps={{ style: { fontWeight: 700 } }}
                    fullWidth
                    label="Model"
                    value={editDevice === 0 ? repairOrder.model ? repairOrder.model : '' : editDeviceData.model ? editDeviceData.model : ''} onChange={handleDeviceInput('model')}
                />
                <Button variant={'outlined'} onClick={handleDeviceEdit}>Ok</Button>
            </Stack>
        return <><Stack direction={{ xs: 'row', sm: 'row' }} spacing={1} sx={{ mb: '25px' }}>
            <DeviceImage brand={repairOrder.brand} />
            <Box>
                <Box display='flex'>
                    <Typography className='clickable' sx={{ py: '2px', px: '5px', border: '1px solid transparent', ":hover": { border: '1px solid', borderColor: 'darkGrey.main', borderRadius: '5px', py: '2px', px: '5px' } }} fontSize='1.3125rem' fontWeight={900} onClick={openDeviceEdit}>
                        {repairOrder.brand} {repairOrder.model}
                    </Typography>
                    {/* <IconButton aria-label="edit" onClick={openDeviceEdit} sx={{ height: '35px', width: '35px' }} color="darkGrey">
                        <EditIcon sx={{ mt: '-5px' }} />
                    </IconButton> */}
                </Box>
                <Button size='small' color='darkGrey' onClick={openCategoryPopover}>{repairOrder.deviceCategory ? repairOrder.deviceCategory.name : `+ ${t('Category')}`}</Button>
                <Popover
                    open={categoryOpen}
                    anchorEl={categoryAnchor}
                    onClose={() => { setCategoryAnchor(null) }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Box p={2} width={'250px'}><CategorySearch onCategoryUpdate={onCategoryUpdate} value={repairOrder.deviceCategory} /></Box>
                </Popover>
            </Box>
        </Stack >
        </>
    }
    // /:repairOrderId/customer
    const setCustomer = async (customer) => {
        try {
            setCustomerLoading(true)
            const resp = await axios.patch(`repair-orders/${repairOrder.id}/customer`, { customerId: customer.id })
            if (resp.status === 200) {
                setRepairOrder({ ...repairOrder, customerId: customer.id, customer: customer });
            }
            setCustomerLoading(false)
        } catch (err) {
            setCustomerLoading(false)
            console.log(err);
        }

    }

    const ClientInput = ({ customer }) => {
        if (customerLoading) {
            return <Skeleton variant="rounded" width={210} height={40} />
        }
        if (!customer)
            return <CustomersSearch setState={setCustomer} />;

        return <>

            <Stack direction={'row'} alignItems={'center'}>
                <Button color='black' variant='outlined' startIcon={<FaceOutlined />} onClick={() => setEditClient(true)}>
                    <Typography
                        sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '230px'
                        }}
                    >
                        {customer?.name}
                    </Typography>
                </Button>
            </Stack>
            {customer?.phone?.length > 0 ?
                <Stack direction={'row'} alignItems={'center'}>
                    <PhoneInTalkOutlinedIcon color={'black'}
                        sx={{ fontSize: '1.2rem', mr: '5px' }} />
                    <Typography mr={0} >
                        {customer.phone}
                    </Typography>
                </Stack>
                : null}
            {customer?.email?.length > 0 ?
                <Stack direction={'row'} alignItems={'center'}>
                    <EmailOutlinedIcon color={'black'}
                        sx={{ fontSize: '1.2rem', mr: '5px' }} />
                    <Typography mr={0}>
                        {customer.email}
                    </Typography>
                </Stack>
                : null}

        </>
    }

    const outsourcedFromSection = () => {
        if (repairOrder.outsourcedFrom) {
            const partner = repairOrder.outsourcedFrom.organization;
            return <>
                <Stack direction={'row'} alignItems={'center'}>
                    <GroupOutlined color={'black'} sx={{ mr: '5px' }} />
                    <Typography
                        // mb='15px'
                        fontWeight={600}
                        fontSize='1'
                        alignItems={'center'}
                        display={'inline'}
                    >
                        {partner.name}
                    </Typography>
                </Stack>
                {partner?.city?.length > 0 ?
                    <Stack direction={'row'} alignItems={'center'}>
                        <LocationCityOutlined color={'black'}
                            sx={{ fontSize: '1.2rem', mr: '5px' }} />
                        <Typography mr={0}>
                            {partner.city}
                        </Typography>
                    </Stack>

                    : null}
            </>
        }
    }

    const onBlurV2 = async (key, newValue) => {
        const value = newValue;

        try {
            const response = await axios.patch(`repair-orders/${repairOrder.organizationCounter}/${key}`, { [key]: value })
            // console.log(response);
            if (response.status !== 200) {
                throw response.status;
            }
            if (response.status === 200) {
                setRepairOrder({ ...repairOrder, [key]: value });
                // console.log(response)
                enqueueSnackbar(`${key} updated succesfully!`, { variant: "success" });
            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }

    const patchEstimate = async (key, newValue) => {
        const value = newValue;

        try {
            const response = await axios.patch(`repair-orders/${repairOrder.id}/${key}`, { [key]: value })
            // console.log(response);
            if (response.status !== 200) {
                throw response.status;
            }
            if (response.status === 200) {
                setRepairOrder({ ...repairOrder, [key]: value });
                // console.log(response)
                enqueueSnackbar(`${key} updated succesfully!`, { variant: "success" });
            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }

    const updatePasswordIsPasswordPattern = (payload) => {
        setRepairOrder(prevState => {
            return { ...prevState, isPasswordPattern: payload }
        })
        setPasswordChanged(true);
    }

    const updatePassword = (payload) => {
        setRepairOrder(prevState => {
            return { ...prevState, password: payload }
        })
        setPasswordChanged(true);
    }

    const handlePasswordUpdate = async () => {
        if(passwordChanged === false) return;
        const data = {
            password: repairOrder.password,
            isPasswordPattern: repairOrder.isPasswordPattern
        }

        try {
            const response = await axios.patch(`repair-orders/${repairOrder.id}/password`, data)
            // console.log(response);
            if (response.status !== 200) {
                throw response.status;
            }
            if (response.status === 200) {
                setPasswordChanged(false);
                enqueueSnackbar(`Password updated succesfully!`, { variant: "success" });
            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
    }
    const handleRemovePattern = async () => {
        setRepairOrder(prevState => {
            return { ...prevState, password: '', isPasswordPattern: false }
        })
        const data = {
            password: "",
            isPasswordPattern: false
        }
        try {
            const response = await axios.patch(`repair-orders/${repairOrder.id}/password`, data)
            // console.log(response);
            if (response.status !== 200) {
                throw response.status;
            }
            if (response.status === 200) {
                // console.log(response)
                enqueueSnackbar(`Password updated succesfully!`, { variant: "success" });
            }
        } catch (err) {
            console.log(err)
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
        handlePasswordUpdate();
    }

    return (
        <>
            <Box  backgroundColor={'white'} py={'15px'}  >
                <Box className='sectionGroup'>
                    <Section variant='sm'>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                {device()}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Stack direction={'column'} spacing={1} justifyContent={'flex-start'} alignItems={{ xs: 'start', sm: 'end' }}  >
                                    {repairOrder.outsourcedFromId ? null :
                                        <ClientInput customer={repairOrder.customer} />
                                    }
                                    {outsourcedFromSection()}
                                </Stack>
                            </Grid>
                        </Grid>
                    </Section>
                    {/* <Typography variant={'h4'}>{t('Device')}</Typography> */}
                    <Section variant='sm'>
                        <Stack direction={'row'} spacing={2}>
                            <ReactiveTextField
                                fullWidth
                                initialState={repairOrder.malfunction}
                                label={t('Malfunction')}
                                onBlurCallback={(newValue) => onBlurV2('malfunction', newValue)}
                                multiline
                                maxRows={4}
                                minRows={4}
                                disabled={repairOrder.outsourcedFromId !== null}
                            // disabled={repairOrder.repairOrderStatusId === 12 || repairOrder.repairOrderStatusId === 10}
                            />
                            <ReactiveTextField
                                fullWidth
                                initialState={repairOrder.note}
                                label={t('Note')}
                                onBlurCallback={(newValue) => onBlurV2('note', newValue)}
                                multiline
                                maxRows={4}
                                minRows={4}
                                disabled={repairOrder.outsourcedFromId !== null}
                            />
                        </Stack>
                    </Section>

                    <Section variant='sm'>
                        <Stack direction='row' spacing={2} >
                            <ReactiveTextField
                                fullWidth
                                initialState={repairOrder.imei}
                                label={'Imei'}
                                onBlurCallback={(newValue) => onBlurV2('imei', newValue)}
                            />
                            <PasswordTextField
                                onBlur={handlePasswordUpdate}
                                value={repairOrder.password}
                                setValue={updatePassword}
                                isPasswordPattern={repairOrder.isPasswordPattern}
                                setIsPasswordPattern={updatePasswordIsPasswordPattern}
                                onRemovePattern={handleRemovePattern}
                            />
                            {/* <ReactiveTextField
                                fullWidth
                                initialState={repairOrder.password}
                                label={t('Password')}
                                onBlurCallback={(newValue) => onBlurV2('password', newValue)}
                            /> */}
                        </Stack>
                    </Section>
                    <Section variant='sm'>
                        <Stack direction='row' spacing={2} >
                            <ReactiveTextField
                                fullWidth
                                initialState={repairOrder.estimatedServices}
                                type='number'
                                label={t("Estimated services")}
                                onBlurCallback={(newValue) => patchEstimate('estimatedServices', newValue)}
                                placeholder={'0.00'}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
                                }}
                            />
                        </Stack>
                        {/* <div className='sectionGroup'>
                    </div> */}
                    </Section>

                </Box>
            </Box>
        </>
    )
}

export default OrderEditGeneral;