import { useRef, useState, useEffect, memo } from 'react';
// import axios from "../../axios-config";
import {
    Box, Tabs, Tab, Button, Divider,
    Badge, TextField, Fab, Stack,
    IconButton,
    Avatar,
    CircularProgress,
    Dialog,
    DialogActions,
    useMediaQuery,
    Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useParams, useNavigate } from "react-router-dom";
import RepairOrderLogPopover from '../EditOrder/RepairOrderLogPopover';
import OrderEditGeneral from '../EditOrder/OrderEditGeneral';
import EditClientDialog from '../EditOrder/Dialogs/EditCustomerDialog';
import Comments from '../EditOrder/Comments/Comments';
import { useTranslation } from 'react-i18next';

import SendIcon from '@mui/icons-material/Send';
import { AttachFile, Close, ImageOutlined, LockOutlined, PersonOutline } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import NewFeatureTooltip from 'components/NewFeatureTooltip/NewFeatureTooltip';
import { useAxios } from 'contexts/AxiosContext';
import { create, isEqual, differenceWith, set } from 'lodash';
import { useSelector } from 'react-redux';
import { name } from 'dayjs/locale/es';
import ImageViewer from 'components/ImageViewer/ImageViewer';
import CommentForm from './CommentForm';




const RepairOrderGeneralPanel = ({ repairOrder, setRepairOrder, }) => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const axios = useAxios().instance;
    const [comments, setComments] = useState(null);
    const [isDoingAction, setIsDoingAction] = useState(false);

    const [inputValue, setInputValue] = useState("");
    const [commentTabIndex, setCommentTabIndex] = useState(0);

    const [editClient, setEditClient] = useState(false);

    const [uploadedImages, setUploadedImg] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(null);
    const [filesToUpload, setFilesToUpload] = useState([]);

    const [imageViewOpen, setImageViewOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [itemIndex, setItemIndex] = useState(0);

    const isMounted = useRef(true);
    const intervalRef = useRef(null);
    const auth = useSelector(state => state.auth);



    useEffect(() => {
        isMounted.current = true;

        const fetchCommentsSafe = async () => {
            try {
                if (isDoingAction) return;
                const response = await axios.get(`/repair-order-comments/all-comments/${repairOrder.id}`);
                if (isMounted.current && response.status === 200) {
                    if (!isEqual(response.data, comments)) {
                        setComments(response.data);
                        const newImages = sortAndMergeImages(response.data);;
                        setImages(newImages);
                    }
                }
            } catch (err) {
                console.error('Error fetching comments:', err);
                // if (isMounted.current) {
                //     enqueueSnackbar('Something went wrong!', { variant: 'error' });
                // }
            }
        };

        fetchCommentsSafe();
        intervalRef.current = setInterval(fetchCommentsSafe, 5 * 1000);

        return () => {
            isMounted.current = false;
            clearInterval(intervalRef.current);
        };
    }, [repairOrder.id, comments, isDoingAction]);

    const handleComment = async (event) => {
        event.preventDefault();
        if (inputValue.trim() !== "" || uploadedImages.length > 0) {
            const fileIds = uploadedImages.map((image) => image.id);
            try {
                setIsDoingAction(true);
                if (inputValue !== "") {
                    setInputValue("");
                }
                const isPublicBool = commentTabIndex === 1;
                // create a new comment object
                const newComment = {
                    id: Math.random(),
                    body: inputValue,
                    isPublic: isPublicBool,
                    createdAt: new Date(),
                    user: {
                        id: auth.userId,
                        name: auth.userName
                    },
                    commentFiles: uploadedImages
                };

                setComments([...comments, newComment]);

                // remove uploaded images
                setUploadedImg([]);
                const response = await axios.post(`/repair-order-comments/add-comment/${repairOrder.id}`, {
                    body: inputValue,
                    isPublic: isPublicBool,
                    fileIds: fileIds
                });
                if (response.status === 200 || response.status === 201) {
                    // console.log(response.data);
                }
            }
            catch (err) {
                console.log(err);
                enqueueSnackbar(`Something went wrong!`, { variant: "error" });
            } finally {
                setIsDoingAction(false);
            }
        }
    };

    const hanldeNewCustomerData = (newCustomer) => {
        setRepairOrder(prevState => {
            return { ...prevState, customer: newCustomer }
        })
    }

    const handleFileDelete = async (imageId) => {
        try {
            const newUploadedImages = uploadedImages.filter(
                (img) => img.id !== imageId
            );

            setUploadedImg(newUploadedImages);

            await axios.delete(`/repair-order-comments/delete-comment-file/${imageId}`);

            // enqueueSnackbar("Image deleted successfully!", { variant: "success" });
        } catch (error) {
            enqueueSnackbar("Error deleting image", { variant: "error" });
            console.error("Error deleting image:", error);
        }
    };

    const handleFileUpload = async (event) => {
        const files = Array.from(event.target.files);

        const validImageTypes = ["image/jpeg", "image/png", "image/webp", "img/jpg"];

        const imageFiles = files.filter((file) =>
            validImageTypes.includes(file.type)
        );
        if (files.length != imageFiles.length) {
            enqueueSnackbar("Please upload only image files (JPEG, PNG, WEBP, JPG).", { variant: "error" });
        }
        if(imageFiles.length == 0) return;

        setFilesToUpload([...imageFiles]);
        const formData = new FormData();

        imageFiles.forEach((file) => {
            formData.append("commentImage", file);
        });

        try {
            setIsUploading(true);
            setUploadProgress(0);

            const response = await axios.post("/repair-order-comments/upload-file",
                formData,
                {
                    onUploadProgress: (progressEvent) => {
                        const percentComplete = Math.round(
                            (progressEvent.loaded / progressEvent.total) * 100
                        );
                        setUploadProgress(percentComplete);
                    },
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );


            // enqueueSnackbar("File uploaded successfully!", { variant: "success" });
            setFilesToUpload([]);
            setUploadedImg((prevUploadedImg) => [
                ...prevUploadedImg,
                ...response.data.data,
            ]);

            // console.log("Upload successful:", response.data);
        } catch (error) {
            enqueueSnackbar("Error uploading images", { variant: "error" });
            console.error("Error uploading images:", error);
        } finally {
            setIsUploading(false);
            setUploadProgress(null);

        }
    };
    const handleImageViewClose = () => {
        setImageViewOpen(false);
    };

    const sortAndMergeImages = (posts) => {
        let allImages = [];
        posts.forEach((post) => {
            post.commentFiles.forEach((img) => {
                allImages.push({
                    originalUrl: img.filePath,
                    url: `${process.env.REACT_APP_API_URL}${img.filePath}`,
                    createdAt: new Date(post.createdAt),
                });
            });
        });

        allImages.sort((a, b) => a.createdAt - b.createdAt);

        const sortedImageUrls = allImages.map((imgObj) => imgObj.url);

        return sortedImageUrls;
    }

    const handleImageClick = (originalUrl) => {
        const searchUrl = `${process.env.REACT_APP_API_URL}${originalUrl}`;
        const index = images.findIndex((img) => img === searchUrl);
        setItemIndex(index);
        setImageViewOpen(true);
    };

    useEffect(() => {
        if (repairOrder.outsourcedFromId != null || repairOrder.outsourcedToId != null) {
            setCommentTabIndex(1);
        }
    }, [])
    {/* <Comments orderId={repairOrder.id} orderIsShared={repairOrder.outsourcedFromId != null || repairOrder.outsourcedToId != null} /> */ }
    if (!repairOrder) return null;
    return <>
        <Box id={'article'} display={'flex'} flex={'1 1 auto'} flexDirection={'column'} minHeight={'1px'} backgroundColor={'white'}>
            <Box display={'flex'} flex={'1 1 auto'} flexDirection={'column'} minHeight={'1px'} sx={{ overflowY: 'auto' }} >
                <Box id='panel-content' flex={'1 1 auto'}>
                    <Box flex={'1 1 auto'} >
                        <OrderEditGeneral
                            repairOrder={repairOrder}
                            setRepairOrder={setRepairOrder}
                            editClient={editClient}
                            setEditClient={setEditClient}
                        />
                    </Box>
                    <Stack gap={'10px'} borderTop={'1px solid lightGrey'} p={"15px"}  >
                        <Stack alignItems={'flex-start'} justifyContent={'flex-start'} gap={'5px'} >
                            <Typography variant="h5">{t('Comments')}</Typography>
                            {!repairOrder.outsourcedFromId && !repairOrder.outsourcedToId ? null : <Tabs value={commentTabIndex} onChange={(e, newValue) => setCommentTabIndex(newValue)}>
                                <Tab label={t("Private")} icon={<LockOutlined sx={{ fontSize: '15px' }} />} iconPosition={'start'}
                                    sx={{ minHeight: '40px', padding: '6px 12px' }}
                                />
                                <Tab label={repairOrder.outsourcedToId ? t("Partner") : t("Customer")} icon={<PersonOutline sx={{ fontSize: '15px' }} />} iconPosition={'start'}
                                    sx={{ minHeight: '40px', padding: '6px 12px' }}
                                />
                            </Tabs>}
                        </Stack>
                        <Box sx={{ width: '100%' }}>
                            <CommentForm
                                inputValue={inputValue}
                                setInputValue={setInputValue}
                                handleComment={handleComment}
                                handleFileUpload={handleFileUpload}
                                uploadedImages={uploadedImages}
                                handleFileDelete={handleFileDelete}
                                filesToUpload={filesToUpload}
                                isUploading={isUploading}
                                uploadProgress={uploadProgress}
                                theme={theme}
                                t={t}
                            />
                        </Box>
                        <Comments
                            order={repairOrder}
                            commentTabIndex={commentTabIndex}
                            comments={comments}
                            setComments={setComments}
                            // setCommentTabIndex={setCommentTabIndex}
                            orderIsShared={repairOrder.outsourcedFromId != null || repairOrder.outsourcedToId != null}
                            isDoingAction={isDoingAction}
                            setIsDoingAction={setIsDoingAction}
                            handleImageClick={handleImageClick}
                        />
                        {repairOrder.outsourcedFromId == null ?
                            <EditClientDialog dialogOpen={editClient} item={repairOrder.customer} handleDialogClose={() => setEditClient(false)} onEdit={hanldeNewCustomerData} />
                            : null
                        }
                    </Stack>
                </Box>
            </Box>
        </Box>
        <Dialog
            fullScreen={isSmallScreen}
            open={imageViewOpen}
            onClose={handleImageViewClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="md"
        >
            {/* <DialogContent> */}
            <ImageViewer images={images} index={itemIndex} fullScreen={isSmallScreen} handleClose={handleImageViewClose} />
            {/* </DialogContent> */}
            <DialogActions>
                <Button onClick={handleImageViewClose}>Close</Button>
            </DialogActions>
        </Dialog>
    </>
}

export default RepairOrderGeneralPanel;