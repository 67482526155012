import React, { useCallback, useMemo } from "react";
import { Box, Typography, Stack, Checkbox, Snackbar,  Button, IconButton, Tooltip, Select, FormControl, InputLabel, MenuItem, } from "@mui/material";

import { useState,  useEffect, memo } from 'react';
import { FixedSizeList, areEqual } from "react-window";

import "./data-table.css";
import { useAxios } from "contexts/AxiosContext";
import { useSelector, useDispatch } from 'react-redux';
import { dataTableActions } from "../../store/data-table";
import EntryDetailsCell from "./EntryDetailsCell";
import DeviceCell from "./DeviceCell";
import MalfunctionCell from "./MalfunctionCell";

import StatusCell from "./StatusCell";
import BudgetCell from "./BudgetCell";
import PublicNoteCell from "./PublicNoteCell";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import StatusChip from "../StatusChip/StatusChip";
import { useSnackbar } from "notistack";
import { useTheme } from '@mui/material/styles';
import { alpha } from "@mui/material";
import {  PaymentsOutlined } from "@mui/icons-material";
import ConfirmationDialog from "common/ConfirmDialog";
import { isEqual,differenceWith } from "lodash";
import ConsoleHelper from "utils/ConsoleHelper";


const WindowTable = ({ filters, ableToSelect, setAbleToSelect, searchInFields }) => {
    const axios = useAxios().instance;
    const [repairOrdersFiltered, setRepairOrdersFiltered] = useState([]);
    const [markAsPaidDialogOpen, setMarkAsPaidDialogOpen] = useState(false);
    const [height, setHeight] = useState(window.innerHeight - 190); // used for adjusting virtual list height

    const dispatch = useDispatch();
    const repairOrders = useSelector(state => state.dataTable.repairOrders)
    const unpaidCheck = useSelector(state => state.dataTable.unpaidCheck)
    const dueToPartnerCheck = useSelector(state => state.dataTable.dueToPartnerCheck)
    const unpaidIds = useSelector(state => state.dataTable.unpaidIds)
    const dueTopartnerIds = useSelector(state => state.dataTable.dueTopartnerIds)
    const { enqueueSnackbar } = useSnackbar();

    const setDataTable = (payload) => {
        dispatch(dataTableActions.setDataTable(payload))
    }

    const [isFetching, setIsFetching] = useState(false);

    const search = useSelector(state => state.dataTable.search);
    const statusesSort = useSelector(state => state.dataTable.statuses)

    const partnersFilter = useSelector(state => state.dataTable.partnersFilter)
    const urgenciesToggle = useSelector(state => state.dataTable.urgenciesToggle)

    const [selectedOrders, setSelectedOrders] = useState([])


    const { t } = useTranslation();
    // 
    // const memoStatusesSort = useMemo(() => statusesSort, [statusesSort]);

    const minWidthOrderColumn = { xs: '230px', md: "225px", lg: "240px" };
    const maxWidthOrderColumn = { xs: '230px', md: "225px", lg: "240px" };

    const minWidthDeviceColumn = { xs: '150px', md: "150px", lg: "200px", xl: "250px" };
    const maxWidthDeviceColumn = { xs: '150px', md: "150px", lg: "200px", xl: "250px" };

    const minWidthMalfunctionColumn = { xs: '200px', md: "200px", lg: "250px", xl: "300px" };
    const maxWidthMalfunctionColumn = { xs: '200px', md: "200px", lg: "250px", xl: "300px" };

    const minWidthStatusColumn = { xs: '170px', md: "170px", lg: "210px" };
    const maxWidthStatusColumn = { xs: '170px', md: "170px", lg: "210px" };

    const minWidthLastCommentColumn = { xs: '200px', md: "130px", lg: "150px", xl: "250px" };
    const maxWidthLastCommentColumn = { xs: '200px', md: "130px", lg: "150px", xl: "250px" };
    const [paymentMethod, setPaymentMethod] = useState(0);

    useEffect(() => {
        const handleResize = () => {
          setHeight(window.innerHeight - 190);
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    useEffect(() => {
        let isMounted = true;
        setIsFetching(true);

        const fetchDataAndUpdateState = async () => {
            try {
                const data = await fetchData();
                if (isMounted) {
                    const dataIsDifferent = !isEqual(data, repairOrders);
                    if (dataIsDifferent) {
                        ConsoleHelper('data is different');
                        setDataTable(data);
                    }
                    setIsFetching(false);
                }
            } catch (error) {
                console.error(error);
                if (isMounted) {
                    setIsFetching(false);
                }
            }
        };

        fetchDataAndUpdateState();

        const intervalId = setInterval(fetchDataAndUpdateState, 10000);

        return () => {
            isMounted = false;
            clearInterval(intervalId);
        };
    }, [repairOrders]);

    useEffect(() => {
        setSelectedOrders([]);
    }, [ableToSelect, statusesSort]);

    const fetchData = useCallback(async () => {
        try {
            const response = await axios.get(`/repair-orders`);
            if (response.status === 200) {
                let data = response.data;
                return data;
            }
            return null;
        } catch (err) {
            console.log(err);
        }
    });

    const handleSelectRow = (order) => {
        // if (selectedOrderIds.includes(id)) {
        //     setSelectedOrderIds(selectedOrderIds.filter(selectedId => selectedId !== id));
        // } else {
        //     setSelectedOrderIds([...selectedOrderIds, id]);
        // }

        if (selectedOrders.find(selectedOrder => selectedOrder.id === order.id)) {
            setSelectedOrders(selectedOrders.filter(selectedOrder => selectedOrder.id !== order.id));
        } else {
            if (selectedOrders.length >= 50) {
                // enqueueSnackbar(`Only 50 orders at once can be updated.`, { variant: "error" });
                return;
            }
            setSelectedOrders([...selectedOrders, order]);
        }
    }

    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    useEffect(() => {

        if (!repairOrders || repairOrders?.length === 0) return;

        const searchVal = search.toLowerCase();
        let filtered = [...repairOrders]
        filtered = sortByStatus(filtered);
        if (urgenciesToggle) {
            filtered = filtered.filter(order => order.levelOfAttention)
        }
        if (dueToPartnerCheck) {
            filtered = filtered.filter(order => dueTopartnerIds.find(id => id === order.id) !== undefined)
        }
        if (unpaidCheck) {
            filtered = filtered.filter(order => unpaidIds.find(id => id === order.id) !== undefined)
        }
        if (partnersFilter.length > 0) {
            filtered = filtered.filter(order => {
                if (order.outsourcedFrom) {
                    const match = partnersFilter.find(element => element.id === order.outsourcedFrom.organization.id);
                    // console.log(match?.name);
                    if (match) {
                        return true;
                    }
                }
                if (order.outsourcedTo) {
                    const match = partnersFilter.find(element => element.id === order.outsourcedTo.organization.id);
                    if (match) {
                        return true;
                    }
                }
                if (!order.outsourcedFrom && !order.outsourcedTo) {
                    const ourOrgMatch = partnersFilter.find(element => element.id === order.organizationId);
                    if (ourOrgMatch) {
                        return true;
                    }
                }
            })
        }

        if (searchVal.length != 0) {
            const searchTerms = searchVal.split(' ').map(term => normalize(escapeRegExp(term)));

            filtered = filtered.filter(order => {
                const orderId = order.outsourcedFrom ? `${order.outsourcedFrom.organization.id}#${order.outsourcedFrom.organizationCounter}` : `${order.organizationId}#${order.organizationCounter}`
                let searchStrings = [];
                if (searchInFields.includes("id")) {
                    searchStrings.push(orderId);
                }
                if (searchInFields.includes("device")) {
                    searchStrings.push(order.brand?.toLowerCase());
                    searchStrings.push(order.model?.toLowerCase());
                }
                if (searchInFields.includes("resolution")) {
                    searchStrings.push(t(order.resolutionType?.name).toLocaleLowerCase());
                }
                if (searchInFields.includes("imei")) {
                    searchStrings.push(order.imei?.toLowerCase());
                }
                if (searchInFields.includes("malfunction")) {
                    searchStrings.push(order.malfunction?.toLowerCase());
                }
                if (searchInFields.includes("customer")) {
                    searchStrings.push(order.customer?.name?.toLowerCase());
                    searchStrings.push(order.customer?.phone?.toLowerCase());
                }
                if (searchInFields.includes("note")) {
                    searchStrings.push(order.note?.toLowerCase());
                }
                if (searchInFields.includes("resolution")) {
                    searchStrings.push(t(order.resolutionType?.name).toLocaleLowerCase());
                }



                const normalizedStringToBeSearched = normalize(searchStrings.join(' '));

                return searchTerms.every(term => normalizedStringToBeSearched.includes(term));

                // const normalizedSearchVal = normalize(escapeRegExp(searchVal));

                // const regexSearch = normalizedStringToBeSearched.search(normalizedSearchVal);

                // return regexSearch != -1;
            })
        }
        else {
            if (!unpaidCheck && !dueToPartnerCheck) {
                filtered = statusFilter(filtered);
            }
        }

        setRepairOrdersFiltered(filtered);

    }, [search, searchInFields, repairOrders, statusesSort, partnersFilter, filters, unpaidCheck, dueToPartnerCheck, urgenciesToggle]);

    function normalize(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    const ordersCheck = () => {


        let repairOrdersFilteredLength = repairOrdersFiltered.length;
        let selectedOrdersLength = selectedOrders.length;

        if (repairOrdersFilteredLength > 0 && repairOrdersFilteredLength == selectedOrdersLength || selectedOrdersLength === 50) {
            return true;
        }
        return false;

    }
    const ordersIsIntermediate = () => {

        let repairOrdersFilteredLength = repairOrdersFiltered.length;
        let selectedOrdersLength = selectedOrders.length;

        if (repairOrdersFilteredLength > 0 && selectedOrdersLength > 0 && (repairOrdersFilteredLength !== selectedOrdersLength && selectedOrdersLength < 50)) {
            return true;
        }
        return false;
    }
    const handleCheckAll = () => {

        const isChecked = ordersCheck();
        if (isChecked) {
            setSelectedOrders([]);
        }
        else {
            setSelectedOrders(repairOrdersFiltered.slice(0, 50));
        }
    }
    const checkSameOrdersStatusId = (orders) => {

        const statusId = orders[0]?.repairOrderStatusId;
        return orders.every(order => order.repairOrderStatusId === statusId);
    }

    const onBulkUpdateStatusCallback = (newOrders) => {
        let newRepairOrdersFiltered = repairOrdersFiltered.map(repairOrder => {
            const updatedOrder = newOrders.find(newOrder => newOrder.id === repairOrder.id);
            return updatedOrder ? updatedOrder : repairOrder;
        });
        let newRepairOrders = repairOrders.map(repairOrder => {
            const updatedOrder = newOrders.find(newOrder => newOrder.id === repairOrder.id);
            return updatedOrder ? updatedOrder : repairOrder;
        });

        newRepairOrdersFiltered = sortByStatus(newRepairOrdersFiltered);
        newRepairOrdersFiltered = statusFilter(newRepairOrdersFiltered);
        setRepairOrdersFiltered(newRepairOrdersFiltered);
        const selectedLength = selectedOrders.length;
        setSelectedOrders([]);
        enqueueSnackbar(`${selectedLength} ${t('orders')} ${t('were updated')}`, { variant: "success" });
        setDataTable(newRepairOrders);
    }

    const sortByStatus = (filtered) => {
        if (!Array.isArray(statusesSort)) {
            console.error('statusesSort is not an array');
            return filtered;
        }
        return filtered.sort((a, b) => {
            const aFind = statusesSort.find(status => status.id == a.repairOrderStatusId);
            if (!aFind) return 1;
            const aSort = statusesSort.indexOf(aFind);

            const bFind = statusesSort.find(status => status.id == b.repairOrderStatusId);
            if (!bFind) return -1;
            const bSort = statusesSort.indexOf(bFind);
            return aSort - bSort;
        });
    }

    const statusFilter = (filtered) => {
        if (!Array.isArray(statusesSort)) {
            console.error('statusesSort is not an array');
            return filtered;
        }

        return filtered.filter(order => {
            const status = statusesSort.find(status => status.id == order.repairOrderStatusId);
            return !status?.isHidden;
        });
    }

    const markSelectedAsPaid = async () => {
        const selectedOrdersIds = selectedOrders.map(order => order.id);
        try {
            setMarkAsPaidDialogOpen(false);
            const newOrders = repairOrdersFiltered.map(order => {
                if (selectedOrdersIds.includes(order.id)) {
                    return { ...order, paid: true };
                }
                return order;
            });
            setRepairOrdersFiltered(newOrders);
            setSelectedOrders([]);
            const response = await axios.post(`/repair-orders/bulk/mark-as-paid`, { repairOrderIds: selectedOrdersIds, paymentMethod: paymentMethod });
            setAbleToSelect(false);
            if (response.status === 200) {
                enqueueSnackbar(`${selectedOrders.length} ${t('repairs')} ${t('marked as paid')}`, { variant: "success" });
            }
        } catch (err) {
            console.log(err)
            if (err.response?.data?.message) {
                enqueueSnackbar(err.response.data.message, { variant: "error" });
            } else {
                enqueueSnackbar(`Something went wrong!`, { variant: "error" });
            }
        }
        finally {
            setSelectedOrders([]);
        }
    }

    const location = useSelector((state) => state.dataTable.location);


    const itemData = useMemo(() => ({
        repairOrdersFiltered,
        ableToSelect,
        selectedOrders,
        handleSelectRow,
        rowStyle: {
            minWidthOrderColumn,
            maxWidthOrderColumn,
            minWidthDeviceColumn,
            maxWidthDeviceColumn,
            minWidthMalfunctionColumn,
            maxWidthMalfunctionColumn,
            minWidthStatusColumn,
            maxWidthStatusColumn,
            minWidthLastCommentColumn,
            maxWidthLastCommentColumn,
        }
    }), [repairOrdersFiltered, ableToSelect, selectedOrders]);

    return (
        <>
            {/* {isFetching ? <LinearProgress sx={{ width: '100%', zIndex: 1000 }} /> : <LinearProgress sx={{ width: '0%' }}/>} */}
            < Stack
                direction={'row'}
                justifyContent
                className="header"
                alignItems={'center'}
            // divider={<Divider orientation="vertical" flexItem sx={{ height:'20px', alignSelf:'center' }} />}
            >
                <Stack spacing={1} className="orderColumn"
                    justifyContent={'center'}
                    direction={'row'}
                    alignItems={'center'}
                    minHeight={'42px'}
                    sx={{
                        minWidth: minWidthOrderColumn,
                        maxWidth: maxWidthOrderColumn,
                    }} >
                    {ableToSelect ?
                        <Checkbox
                            checked={ordersCheck()}
                            indeterminate={ordersIsIntermediate()}
                            onChange={handleCheckAll}
                        />
                        : null}
                    <Typography>
                        {t("Repair")}
                    </Typography>
                    <Typography>
                        •
                    </Typography>
                    <Typography>
                        {repairOrdersFiltered.length}
                    </Typography>

                </Stack>
                <Stack className="deviceColumn" justifyContent={'center'} direction={'row'}
                    sx={{
                        minWidth: minWidthDeviceColumn,
                        maxWidth: maxWidthDeviceColumn,
                    }}
                >{t("Device")}</Stack>
                <Stack className="malfunctionColumn" justifyContent={'center'} direction={'row'}
                    sx={{
                        minWidth: minWidthMalfunctionColumn,
                        maxWidth: maxWidthMalfunctionColumn,
                    }}
                >{t("Malfunction")}</Stack>
                {/* <Grid item>Comment</Grid> */}
                <Stack className="statusColumn" justifyContent={'center'} direction={'row'}
                    sx={{
                        minWidth: minWidthStatusColumn,
                        maxWidth: maxWidthStatusColumn,
                    }}>{t("Status")}</Stack>
                <Stack className="lastCommentColumn" justifyContent={'center'} direction={'row'}
                    sx={{
                        minWidth: minWidthLastCommentColumn,
                        maxWidth: maxWidthLastCommentColumn,
                    }}>{t("Last Comment")}</Stack>
                <Stack className="servicesColumn" flex={1} direction={'row'} justifyContent={'center'}>{t("Services")}</Stack>
            </Stack>
            {isFetching && repairOrders?.length === 0 ? "Loading.." :

                repairOrders?.length === 0 ? <><Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="200px">
                    <Typography variant="h4" component="h2" gutterBottom>
                        {t("It's a bit lonely here...")}
                    </Typography>
                    <Typography variant="subtitle1" component="p" color="textSecondary" gutterBottom>
                        {t("Let's get to work! Add your first entry to fill this space.")}
                    </Typography>
                </Box></> :

                    repairOrdersFiltered.length === 0 ? <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="200px"><Typography variant="h4" component="h2" gutterBottom>{t("No data matching your filters.")}</Typography></Box> :
                        < FixedSizeList
                            className="no-scrollbars"
                            style={{ overflowX: 'hidden' }}
                            height={height}
                            initialScrollOffset={location * 170}
                            onItemsRendered={(props) => {
                                dispatch(dataTableActions.setTableLocation(props.visibleStartIndex));
                            }}
                            itemData={itemData}
                            itemCount={repairOrdersFiltered.length}
                            itemSize={170}
                            ableToSelect={ableToSelect}
                        >
                            {Row}
                        </FixedSizeList>
            }
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={selectedOrders?.length > 0}
            // onClose={handleClose}
            // message="I love snacks"
            >
                <Box sx={{ backgroundColor: "#252628", px: '20px', py: '10px', borderRadius: '50px' }}>
                    <Stack alignItems={'center'} color={'white'} direction={'row'} spacing={3} >
                        {checkSameOrdersStatusId(selectedOrders) && selectedOrders?.length > 0 ? <>
                            <Typography fontWeight={500} variant={'p'}>{selectedOrders.length}{selectedOrders.length == 50 ? "/50" : null} {selectedOrders.length > 1 ? t("orders selected") : t("order selected")}</Typography>
                            <StatusChip repairOrderData={selectedOrders[0]} isBulk repairDataBulk={selectedOrders} onUpdateStatusCallback={onBulkUpdateStatusCallback} />
                        </>
                            :
                            <>
                                <Tooltip title={t("Can't update multiple statuses")} fontSize={15} placement='top'>

                                    <Button variant="contained" sx={{
                                        color: 'rgba(255, 255, 255, 0.7)', // light grey text
                                        backgroundColor: 'rgba(255, 255, 255, 0.12)', // light grey background
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 0.12)', // keep the same color on hover
                                        },
                                    }}>{t("Change Status")}</Button>
                                </Tooltip>
                            </>
                        }
                        <Tooltip title={t('general.markAsPaid')} fontSize={15} placement='top'>
                            <IconButton color="inherit" aria-label="mark as paid" onClick={() => setMarkAsPaidDialogOpen(true)}>
                                <PaymentsOutlined />
                            </IconButton>
                        </Tooltip>

                    </Stack>
                </Box>
            </Snackbar>
            <ConfirmationDialog
                open={markAsPaidDialogOpen && selectedOrders?.length > 0}
                title={t("dialogs.markRepairsAsPaid.title")}
                message={<Stack spacing={2}>
                    <div>{t("dialogs.markRepairsAsPaid.message", { quantity: selectedOrders?.length })}</div>
                    <FormControl variant={'filled'} >
                        <InputLabel >{t('Payment method')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            label="Payment method"
                            value={paymentMethod}
                            onChange={(event) => setPaymentMethod(event.target.value)}
                        >
                            <MenuItem value={0}>{t('Cash')}</MenuItem>
                            <MenuItem value={1}>{t('Card')}</MenuItem>
                            <MenuItem value={2}>{t('Transfer')}</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>}
                onConfirm={() => {
                    markSelectedAsPaid()
                }}
                onClose={() => setMarkAsPaidDialogOpen(false)}
            />
            {/* <Dialog open={markAsPaidDialogOpen} >
                <DialogTitle>{t("dialogs.markRepairsAsPaid.title")}</DialogTitle>
                <DialogContent>
                    <Typography>{t("dialogs.markRepairsAsPaid.message")}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setMarkAsPaidDialogOpen(false)}>{t("dialogs.markRepairsAsPaid.cancel")}</Button>
                    <LoadingButton
                        variant="contained"
                        onClick={() => {
                            setMarkAsPaidDialogOpen(false);
                            markSelectedAsPaid()
                        }}
                        loading={bulkPaymentLoading}
                        sx={{
                            '&.Mui-disabled': {
                                backgroundColor: theme => alpha(theme.palette.primary.main, 0.9),
                            },
                        }}
                    >
                        {t("dialogs.markRepairsAsPaid.confirm")}
                    </LoadingButton>
                </DialogActions>
            </Dialog> */}
        </>
    );
}

const Row = memo(({ data, index, style, onClickRequestOrder }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    let dayjs = require('dayjs')
    const navigate = useNavigate();
    const items = data.repairOrdersFiltered;
    const ableToSelect = data.ableToSelect;
    const selectedOrders = data.selectedOrders;
    const handleSelectRow = data.handleSelectRow;
    const { minWidthOrderColumn, maxWidthOrderColumn, minWidthDeviceColumn,
        maxWidthDeviceColumn, minWidthMalfunctionColumn, maxWidthMalfunctionColumn,
        minWidthStatusColumn, maxWidthStatusColumn, minWidthLastCommentColumn, maxWidthLastCommentColumn } = data.rowStyle;

    const theme = useTheme();
    const updateRowStatus = useCallback(async (updatedRow) => {
        try {
            dispatch(dataTableActions.setDataTableRow(updatedRow));
        } catch (err) {
            console.log(err);
        }
    }, [dispatch]);

    const handleRowClick = useCallback((index) => {
        if (ableToSelect) {
            handleSelectRow(items[index]);
            return;
        }
        if (items[index].isRequest) {
            enqueueSnackbar(t(`Requests can't be opened.`), { variant: "warning" });
            return;
        }
        navigate(`/editEntry/${items[index].organizationCounter}`);
    }, [ableToSelect, handleSelectRow, items, navigate, enqueueSnackbar, t]);
    
    if (!items[index]) {
        return null;
    }

   



    if (!items[index]) {
        return null;
    }
    const statusClass = items[index].repairOrderStatus ? items[index].repairOrderStatus.name.toLowerCase().split(' ').map((word, index) => index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)).join('') : "not-clickable";


    let statusToColor = theme.palette.lightGrey.main;
    if (statusToColor !== "not-clickable") {
        const statusColor = theme.palette[statusClass];
        if (statusColor) {
            statusToColor = statusColor.main;
        }
    }
    return (
        <div style={style} className={"no-scrollbars"}>
            <Stack
                direction={'row'}
                className={` row clickable`}
                role="rowgroup"
                sx={{ width: '100%', border: '2.5px solid', borderColor: statusToColor }}
                onClick={() => handleRowClick(index)}
            // /editEntry/${rowData.organizationCounter}

            >

                <Box className="first table-content orderColumn" role="cell"
                    sx={{
                        minWidth: minWidthOrderColumn,
                        maxWidth: maxWidthOrderColumn,
                        backgroundColor: alpha(statusToColor, 0.1)
                    }}>
                    <EntryDetailsCell rowData={items[index]} ableToSelect={ableToSelect} selectedOrders={selectedOrders} handleSelectRow={handleSelectRow} />
                </Box>
                <Box className="flex-row table-content deviceColumn" role="cell"
                    sx={{
                        minWidth: minWidthDeviceColumn,
                        maxWidth: maxWidthDeviceColumn,
                    }}
                >
                    <DeviceCell rowData={items[index]} />
                </Box>
                <Box className="flex-row table-content malfunctionColumn" role="cell"
                    sx={{
                        minWidth: minWidthMalfunctionColumn,
                        maxWidth: maxWidthMalfunctionColumn,
                    }}
                >
                    <MalfunctionCell rowData={items[index]} />
                </Box>
                {/* <div className="flex-row content" role="cell">
                    <PublicNoteCell rowData={items[index]} />
                </div> */}
                <Box className="flex-row table-content statusColumn" role="cell"
                    sx={{
                        minWidth: minWidthStatusColumn,
                        maxWidth: maxWidthStatusColumn,
                    }}
                >
                    <StatusCell onUpdateStatusCallback={updateRowStatus} repairOrderData={items[index]} />
                </Box>
                <Box className="flex-row table-content lastCommentColumn" role="cell"
                    sx={{
                        minWidth: minWidthLastCommentColumn,
                        maxWidth: maxWidthLastCommentColumn,
                    }}
                >
                    <PublicNoteCell rowData={items[index]} />
                </Box>
                <Box className="flex-row table-content servicesColumn" role="cell" >
                    <BudgetCell rowData={items[index]} />
                </Box>
            </Stack>
        </div>
    );
}, areEqual);

export default memo(WindowTable);
