import {
    List, ListItem, ListItemButton, ListItemText, ListItemAvatar, Avatar,
    Typography, Paper, Tooltip, Stack, Checkbox,
    Snackbar, Box, Chip, IconButton, Dialog, TextField, DialogTitle, DialogContent, DialogActions, Button, InputAdornment, ToggleButton, FormControl, InputLabel, Select, MenuItem, FormControlLabel, ListItemIcon
} from '@mui/material'
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { useTranslation } from 'react-i18next';
import { formatCurrency, getCurrencySymbol } from '../../utils/currency';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from "react-redux";
import { AddLinkOutlined, ControlPointDuplicateOutlined, DeleteOutline, DevicesOutlined, Edit, LinkOutlined, MobileFriendlyOutlined, MobileOffOutlined, PriceChangeOutlined, WarningTwoTone } from '@mui/icons-material';
import DebouncedTextField from '../../common/DebouncedTextField';
import { useSnackbar } from 'notistack';
import { set } from 'lodash';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import axios from "../../axios-config";
import DialogComponent from '../../common/DialogComponent';


const CatalogAccessDialog = ({ dialogOpen, handleDialogClose, ourOrganization, }) => {
    const { t } = useTranslation();
    const [partners, setPartners] = useState(null);
    const [sharedToPartners, setSharedToPartners] = useState(null);

    useEffect(() => {
        if (!dialogOpen) return;
        const getPartners = async () => {
            const response = await axios.get('organizations/partners');
            console.log(response.data);
            setPartners(response.data.sort((a, b) => a.name.localeCompare(b.name)));
        }
        const getSharedServiceCatalogs = async () => {
            const response = await axios.get('service-catalogs/shared-service-catalogs');
            console.log(response.data);
            setSharedToPartners(response.data);
        }
        getPartners();
        getSharedServiceCatalogs();
    }, [dialogOpen]);

    const closeDialog = () => {

        handleDialogClose();
    }

    const handleToggle = (partner) => async () => {
        const isAlreadyShared = sharedToPartners?.findIndex(sharedPartner => sharedPartner.sharedWithOrganizationId === partner.id) !== -1;
        console.log(isAlreadyShared);
        if (!isAlreadyShared) {
            const newSharedToPartners = { organizationId: ourOrganization.id, sharedWithOrganizationId: partner.id };
            setSharedToPartners([...sharedToPartners, newSharedToPartners]);
            const response = await axios.put(`service-catalogs/shared-service-catalogs/${partner.id}`);
        } else {
            // const partnerIndex = sharedToPartners.findIndex(sharedPartner => sharedPartner.sharedWithOrganizationId === partner.id);
            setSharedToPartners(sharedToPartners.filter(sharedPartner => sharedPartner.sharedWithOrganizationId !== partner.id));
            const response = await axios.delete(`service-catalogs/shared-service-catalogs/${partner.id}`);
            // console.log(response.data);
            // setSharedToPartners(sharedToPartners.filter(sharedPartner => sharedPartner.id !== sharedToPartners[partnerIndex].id));
        }
    }

    return <Dialog open={dialogOpen} onClose={closeDialog} fullWidth maxWidth={'xs'}>
        <DialogTitle variant='h4'>
            {t("Sharing permissions")}
        </DialogTitle>
        <DialogContent>
            <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

                {partners?.map((partner) => {
                    const labelId = `checkbox-list-secondary-label-${partner}`;
                    return (
                        <ListItem
                            key={partner.id}
                            disablePadding
                        >
                            <ListItemButton onClick={handleToggle(partner)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="end"
                                        checked={sharedToPartners?.findIndex(sharedPartner => sharedPartner.sharedWithOrganizationId === partner.id) !== -1}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${partner.name} `} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}

            </List>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleDialogClose}>{t("Close")}</Button>
        </DialogActions>
    </Dialog>
}

export default CatalogAccessDialog;