import * as React from 'react';
import { useEffect, useState } from 'react';

import {
  Button, Checkbox, Grid, Stack, DialogContent, DialogContentText, DialogActions, Typography, Box,
  InputLabel, Select, MenuItem, FormControl, TextField, InputAdornment, Tooltip, CircularProgress
} from "@mui/material";
import { Search } from "@mui/icons-material";
import AsyncAutoComplete from "../AsyncAutoComplete/AsyncAutoComplete";
import { updateNewEntryModalState } from "../../store/actions/newEntry";
import { connect } from "react-redux";
import CustomersSearch from './CustomersSearch'
import AddServiceButton from "../AddServiceButton/AddServiceButton";
import EmergencyCheckbox from "../EmergencyCheckbox/EmergencyCheckbox";
import { useNavigate } from "react-router-dom";
import axios from "../../axios-config";
import { useSelector } from 'react-redux';
// Print
import PrintComponent from "../Printing/PrintComponent";
import { useReactToPrint } from "react-to-print";

// ICONS
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import ReactiveTextField from '../ReactiveTextField/ReactiveTextField';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

const Step2 = ({ repairOrderAdded, partners, setRepairOrderAdded, closeModal }) => {

  const { t } = useTranslation();

  const currency = useSelector((state) => state.auth.currency);
  const [settings, setSettings] = useState(null);
  const componentRef = React.useRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const PrintButton = () => <Button startIcon={<LocalPrintshopOutlinedIcon />}> Print ticket</ Button>
  const OpenOrder = (props) => <Button {...props} startIcon={<OpenInNewOutlinedIcon />}> {t('Go to order')}</ Button>

  const updateEmergency = (value) => {
    setRepairOrderAdded(prevState => {
      return { ...prevState, orderData: { ...prevState.orderData, levelOfAttention: value } }
    })
  }

  const [printLoading, setPrintLoading] = useState(false);
  const [organization, setOrganization] = useState({});
  const [outsourceTo, setOutsourceTo] = useState(0);
  const [outsourceLoading, setOutsourceLoading] = useState(false)
  const [repairPayments, setRepairPayments] = useState([]);

  const handleOnBeforeGetContent = () => {
    return new Promise(async (resolve, reject) => {
      const paymentsResponse = await axios.get(`/repair-orders/${repairOrderAdded.id}/payments`);

      if (paymentsResponse.status === 200) {
        setRepairPayments(paymentsResponse.data);
      }
      const response = await axios.get(`/organizations`);
      // console.log(response);
      const successfuly = response.status === 200 || response.status === 201;
      if (successfuly) {
        setOrganization(response.data);
      }
      setPrintLoading(false);
      resolve();
    });
  }

  const navigateToEditOrder = () => {
    closeModal();
    navigate(`/editEntry/${repairOrderAdded.organizationCounter}`);
  }
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Ticket",
    onBeforeGetContent: handleOnBeforeGetContent,
    // onBeforePrint: handleBeforePrint,
    // onAfterPrint: handleAfterPrint,
    // removeAfterPrint: true
  });

  const handleOutsourceSelect = async (e) => {
    const value = e.target.value;
    const prevState = outsourceTo;
    try {

      setOutsourceTo(value);
      if (value <= 0) {
        const response = await axios.delete(`repair-orders/outsource/${repairOrderAdded.id}`);

        return;
      }
      if (!repairOrderAdded) {
        console.log("Missing the added repair order.")
        return;
      }
      const data = { toOrganizationId: value };

      const response = await axios.put(`repair-orders/outsource/${repairOrderAdded.id}`, data);
      if (response.status == 409) {
      }
      // console.log(response);
    } catch (err) {
      console.log(err)
      setOutsourceTo(prevState);
    }
  }

  return (
    <>
      <DialogContent id='dialogcontent'>
        <Stack spacing={2}>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <FormControl fullWidth variant="filled">
              <InputLabel>{t('Outsource to partner')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Payment method"
                value={outsourceTo}
                onChange={handleOutsourceSelect}
                endAdornment={outsourceLoading ? <Box sx={{ display: 'flex', marginRight: '25px' }}>
                  <CircularProgress color="inherit" />
                </Box> : null}
              >
                <MenuItem value={0}>{t('Select a partner')}</MenuItem>
                {partners.map(partner => <MenuItem value={partner.id} key={partner.id}>{partner.name}</MenuItem>)}
              </Select>
            </FormControl>
            <Tooltip title={t('If you want a partner to handle the order, you can outsource it to them.')}>
              <InfoOutlinedIcon />
            </Tooltip>
          </Stack>
          {/* <ReactiveTextField
            initialState={0}
            type='number'
            label="Estimated services"
            // onBlurCallback={(newValue) => patchEstimate('estimatedServices', newValue)}
            placeholder={'0.00'}
            InputProps={{
              startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
            }}
          /> */}
          <Stack direction={'row'} spacing={2} mb={2} alignItems={'center'} justifyContent={'center'}>
            <EmergencyCheckbox repairOrder={repairOrderAdded} onChange={updateEmergency} />
            <OpenOrder onClick={navigateToEditOrder} />
            <Button startIcon={<LocalPrintshopOutlinedIcon />} onClick={handlePrint}>{t('Print ticket')}</Button>
          </Stack>
          <Stack direction={'row'} justifyContent="center" spacing={2}>
          </Stack>
        </Stack>
        <div style={{ display: "none" }}>
          {printLoading ? null :
            <PrintComponent value={0} ref={componentRef} settings={settings} repairOrder={repairOrderAdded} organization={organization} repairPayments={repairPayments} />
          }
        </div>
      </DialogContent>
    </>
  );
};


export default Step2;
