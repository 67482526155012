import { Fragment } from "react";
import {
  Container,
  Card,
  CardContent,
  Paper,
  Typography,
  Box,
  Grid,
  Button,
  Popover,
  Stack

} from "@mui/material";
import * as React from 'react';
import LoginForm from "./components/LoginForm";
import RegisterForm from "./components/RegisterForm"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Logo from "../../images/dalvado-logo.png";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate, useLocation, useNavigate, Outlet } from "react-router-dom";
import { useTheme } from '@emotion/react';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import "./AuthPage.css";
import LanguageButton from "../../components/LanguageButton/LanguageButton";
import AuthCard from "./AuthCard";

const AuthPage = () => {
  const [register, setRegister] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const navigate = useNavigate();

  const toggleRegister = () => {
    setRegister(true);
  };

  const toggleLogin = () => {
    setRegister(false);
  };

  const handleLoginError = (errorMessage) => {
    setLoginError({ errorMessage: errorMessage });
  };


  const theme = useTheme();

  document.body.style.backgroundColor = theme.palette.lightBlue.main;

  const PublicRoute = ({ children }) => {
    const location = useLocation();
    const auth = useSelector(state => state.auth.isAuth);
    return !auth ? children
      : <Navigate to="/" replace state={{ from: location }} />;
  }

  return (
    <PublicRoute>
      <AuthCard >
        <Outlet />
      </AuthCard>
    </PublicRoute >
  );
};
export default AuthPage;
