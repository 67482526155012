import React, { useState } from "react";
import Notification from "../Notification"
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SubjectRounded } from "@mui/icons-material";

function RepairUpdateNotification({ notification, handleClose }) {

    let navigate = useNavigate();
    const { t } = useTranslation();
    const handleNotificationClick = () => {

        if (!notification.repairOrder) return;
        navigate(`/editEntry/${notification.repairOrder.organizationCounter}`, { replace: true });
        handleClose();
    };

    const modifiedTitle = () => {
        return <Box component={'span'}>{t("notifications.repairUpdated.title")}</Box>
    }

    const getBody = () => {
        if (!notification.performedBy) {
            return '';
        }
        let fields = notification.meta?.fields;
        let fieldNames = fields?.map(field => t(`${field}`));
      
        return t('notifications.repairUpdated.bodyWithPerformedBy', { performedBy: notification.performedBy, fieldNames });
    }

    return (
        <Notification
            notification={notification}
            id={notification.id}
            isRead={notification.isRead}
            avatar={<Avatar sx={{ bgcolor: 'orange.main' }}><SubjectRounded /></Avatar>}
            body={getBody()}
            title={modifiedTitle()}
            handleNotificationClick={handleNotificationClick}
        />
    );
}

export default RepairUpdateNotification;