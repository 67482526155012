import { createSlice } from '@reduxjs/toolkit'
import { update } from 'lodash';

const initialAuthState = {
    organizationName: localStorage.getItem('organizationName'),
    organizationId: localStorage.getItem('organizationId'),
    userName: localStorage.getItem('userName'),
    userId: localStorage.getItem('userId'),
    email: localStorage.getItem('email'),
    subscriptionType: localStorage.getItem('subscriptionType'),
    isActiveSubscription: localStorage.getItem('isActiveSubscription'),
    startDate: localStorage.getItem('startDate'),
    endDate: localStorage.getItem('endDate'),
    token: localStorage.getItem('token'),
    isAuth: localStorage.getItem('isAuth'),
    verified: localStorage.getItem('verified'),
    currency: localStorage.getItem('currency')
};

const authSlice = createSlice({
    name: 'authentification',
    initialState: initialAuthState,
    reducers:
    {
        login(state, action) {
            state.organizationName = action.payload.organizationName;
            state.organizationId = action.payload.organizationId;
            state.userName = action.payload.userName;
            state.token = `Bearer ${action.payload.token}`;
            state.isAuth = true;
            state.userId = action.payload.userId;
            state.subscriptionType = action.payload.subscriptionType;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.verified = action.payload.verified;
            state.currency = action.payload.currency;
            state.email = action.payload.email;
            localStorage.setItem('organizationName', action.payload.organizationName);
            localStorage.setItem('organizationId', action.payload.organizationId);
            localStorage.setItem('userName', action.payload.userName);
            localStorage.setItem('email', action.payload.email);
            localStorage.setItem('token', `Bearer ${action.payload.token}`);
            localStorage.setItem('isAuth', true);
            localStorage.setItem('userId', action.payload.userId);
            localStorage.setItem('subscriptionType', action.payload.subscriptionType);
            localStorage.setItem('startDate', action.payload.startDate);
            localStorage.setItem('endDate', action.payload.endDate);
            localStorage.setItem('verified', action.payload.verified);
            localStorage.setItem('verified', action.payload.verified);
            localStorage.setItem('currency', action.payload.currency);
        },
        authToken(state, action) {
            state.token = `Bearer ${action.payload}`;
        },
        updateAuthOrganization(state, action) {
            // console.log(action.payload);
            state.organizationName = action.payload.name;
            state.currency = action.payload.currency;
            localStorage.setItem('organizationName', action.payload.name);
            localStorage.setItem('currency', action.payload.currency);

            console.log(`currency: ${action.payload.currency}`);
        },
        updateAuthUserName(state, action) {
            state.userName = action.payload;
        },
        updateSubscription(state, action) {
            state.subscriptionType = action.payload.subscriptionType;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        updateActiveSubscription(state, action) {
            state.isActiveSubscription = action.payload;
            localStorage.setItem('isActiveSubscription', action.payload);
        },
        logout(state) {
            state.organizationName = '';
            state.token = ``;
            state.userName = '';
            state.isAuth = false;
            state.userId = '';
            state.verified = false;
            state.email = '';
            localStorage.removeItem('organizationName');
            localStorage.removeItem('organizationId');
            localStorage.removeItem('userName');
            localStorage.removeItem('email');
            localStorage.removeItem('userId');
            localStorage.removeItem('token');
            localStorage.removeItem('isAuth');
            localStorage.removeItem('subscriptionType');
            localStorage.removeItem('startDate');
            localStorage.removeItem('endDate');
            localStorage.removeItem('verified');
            localStorage.removeItem('currency');
            localStorage.removeItem('statuses')
            // localStorage.removeItem('verified');
        },
        verifyUser(state) {
            state.verified = true;
            localStorage.setItem('verified', true);
        },
        unverifyUser(state) {
            state.verified = false;
            localStorage.setItem('verified', false);
        }
    }
})

export const authActions = authSlice.actions;


export default authSlice.reducer;