import React, { useState, useEffect } from 'react';
import { Box, Button, Container, Divider, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import { name } from 'dayjs/locale/es';
import Suppliers from './Suppliers/Suppliers';
import AllProducts from './AllProducts/AllProducts';
import { set } from 'lodash';
import { useAxios } from 'contexts/AxiosContext';
import TransferPage from './Transfer/TransferPage';
import Inventory from './Inventory/Inventory';
import { useTranslation } from 'react-i18next';



const InventoryPage = () => {
    const [inventory, setInventory] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    // Create a warehouse context to share the warehouse state
    // with other components
    const [warehouseLoading, setWarehouseLoading] = useState(true);
    const [warehouse, setWarehouse] = useState(null);
    const axios = useAxios().instance;
    const {t} = useTranslation();

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    useEffect(() => {
        // Fetch inventory data from API or database
        // and update the state
        fetchDefaultWarehouse();
    }, []);

    const fetchDefaultWarehouse = async () => {
        try {
            // Make API call or database query to fetch inventory data
            setWarehouseLoading(true);
            const response = await axios.get('/inventory/warehouse/default');
            if (response.status === 200) {
                const data = await response.data;
                setWarehouse(data);
            }
        } catch (error) {
            console.error(error);
        }
        setWarehouseLoading(false);
    };

    return (
        <>
            <Box className={'container mainContainer docsContainer '} sx={{ px: '20px', py: '10px' }}>
                <div className='headerPush'></div>
                <Stack direction={'row'} alignItems={'flex-start'} spacing={1}>
                    <Typography variant='h3'>{t("Inventory Management")}</Typography>
                    <Typography variant='caption' pt={'10px'}>(BETA)</Typography>
                </Stack>
                <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label={t("All Products")} />
                    <Tab label={t("Warehouse Inventory")} />
                    {/* <Tab label="Transfers" /> */}
                    {/* <Tab label="Suppliers" /> */}
                    {/* <Tab label="Assets" /> */}
                </Tabs>
                <Divider sx={{ my: '15px' }} />
                {/* Inventory content based on selected tab */}
                {!warehouse && warehouseLoading && <Typography variant='body'>{t("Loading")}...</Typography>}
                {!warehouse && !warehouseLoading && <Typography variant='body'>{t("No warehouse found")}</Typography>}
                {warehouse && <>
                    {tabValue === 0 && <AllProducts warehouse={warehouse} />}
                    {tabValue === 1 && <Inventory warehouse={warehouse} />}
                    {tabValue === 2 && <TransferPage warehouse={warehouse} />}
                    {tabValue === 3 && <Suppliers warehouse={warehouse} />}
                </>
                }
            </Box>
        </>
    );
};



export default InventoryPage;
