import { Alert, Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { string, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { CheckCircleOutline, RefreshOutlined, WarningAmberOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useAxios } from "contexts/AxiosContext";
import CodeInput from "components/CodeInput/CodeInput";
const PasswordRecovery = () => {
    const { t, i18n } = useTranslation();
    const axios = useAxios().instance;
    const [noAccountFound, setNoAccountFound] = useState(false);
    const [tooManyCodes, setTooManyCodes] = useState(false);

    const navigate = useNavigate();
    const schema = z.object({
        email: string().email({ message: "Required" })
    })

    const [dataSent, setDataSent] = useState(null);

    const { handleSubmit, control, watch, formState: { isSubmitting, isDirty, isValid, errors } } = useForm({ resolver: zodResolver(schema) });
    const handleRequestRecovery = async (data) => {
        try {
            const response = await axios.post('/auth/request-password-recovery', data);
            if (response.status === 200) {
                setNoAccountFound(false);
                setDataSent(data);
            }
        } catch (error) {
            setDataSent(null);
            if (error.response && error.response.status === 404) {
                // Handle 404 error (user not found)
                setNoAccountFound(true);
            } else if (error.response && error.response.status === 429) {
                setTooManyCodes(true);
            } else {
                // Handle other types of errors
            }
        }
    };

    const handleNavigationToLogin = () => {
        navigate('/auth/login');
    }

    return <>
        {dataSent ? <VerifyCode dataSent={dataSent} handleRequestRecovery={handleRequestRecovery} /> :
            <PasswordRecoveryRequestForm
                handleSubmit={handleSubmit(handleRequestRecovery)}
                handleNavigationToLogin={handleNavigationToLogin}
                control={control}
                errors={errors}
                isSubmitting={isSubmitting}
                noAccountFound={noAccountFound}
                tooManyCodes={tooManyCodes}
                t={t}
            />
        }
    </>
}

const PasswordRecoveryRequestForm = ({ handleSubmit, handleNavigationToLogin, control, errors, isSubmitting, noAccountFound, tooManyCodes, t }) => {
    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={2} mt={'25px'}>
                <Typography variant={'h4'} textAlign={'center'}>{t("Password Recovery")}</Typography>
                <Typography variant={'body1'} textAlign={'center'}>{t("Please enter your email address. We will send you a code to verify your identity.")}</Typography>
                {noAccountFound && <Alert severity="error" sx={{ border: '1px solid rgba(0,0,0,0.25)', mb: '15px' }} icon={<WarningAmberOutlined />}>
                    <Stack alignItems={'flex-start'}>
                        <Typography fontWeight={500} variant={'p'}>{t("Account not found!")}</Typography>
                    </Stack>
                </Alert>}
                {tooManyCodes && <Alert severity="error" sx={{ border: '1px solid rgba(0,0,0,0.25)', mb: '15px' }} icon={<WarningAmberOutlined />}>
                    <Stack alignItems={'flex-start'}>
                        <Typography fontWeight={500} variant={'p'}>{t("Too many requests! Try again later.")}</Typography>
                    </Stack>
                </Alert>}
                <Controller
                    name="email"
                    control={control}
                    defaultValue={''}
                    render={({ field }) => <TextField
                        {...field}
                        name="email"
                        label={"Email"}
                        variant="filled"
                        error={!!errors.email}
                        helperText={t(errors.email?.message)}
                    />}
                />
                <LoadingButton type="submit" variant="contained" loading={isSubmitting} >{t("Continue")}</LoadingButton>
                <Stack alignItems={'center'}>
                    <Button onClick={handleNavigationToLogin}>{t("Go back")}</Button>
                </Stack>
            </Stack>
        </form>
    );
}

const VerifyCode = ({ dataSent, handleRequestRecovery }) => {

    const { t, i18n } = useTranslation();
    const [incorrectCode, setIncorrectCode] = useState(false);
    const [temporaryToken, setTemporaryToken] = useState(null);
    const axios = useAxios().instance;
    const [codeResent, setCodeResent] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCodeVerification = async (codeInput) => {
        try {
            const data = { code: codeInput };
            const response = await axios.post('/auth/verify-password-recovery-code', data);
            console.log(response)
            if (response.status === 200) {
                setTemporaryToken(response.data);
            }
        } catch (error) {
            setIncorrectCode(true)
        }
    }

    const handleResendCode = async () => {
        setLoading(true);
        try {
            const response = await axios.post('/auth/request-password-recovery', dataSent);
            console.log(dataSent)
            if (response.status === 200) {
                setCodeResent(true);
                setTimeout(() => setCodeResent(false), 5000); // Hide the message after 5 seconds

            }
        } catch (error) {

        }
        setLoading(false);
    }

    return temporaryToken ? <UpdatePassword temporaryToken={temporaryToken} /> : <>
        {incorrectCode && <Alert severity="error" sx={{ border: '1px solid rgba(0,0,0,0.25)', mb: '15px' }} icon={<WarningAmberOutlined />}>
            <Stack alignItems={'flex-start'}>
                <Typography fontWeight={500} variant={'p'}>{t("The code you entered is expired or not valid. Please try again or resend the code.")}</Typography>
            </Stack>
        </Alert>}
        {codeResent && <Alert severity="success" sx={{ border: '1px solid rgba(0,0,0,0.25)', mb: '15px' }} icon={<CheckCircleOutline />}>
            <Stack alignItems={'flex-start'}>
                <Typography fontWeight={500} variant={'p'}>{t("A new code has been sent to your email.")}</Typography>
            </Stack>
        </Alert>}
        <Box>
            <Stack direction={"column"} alignItems={'center'} mb={'15px'}>
                <Typography variant={'h4'} textAlign={'center'}>{t("Security Check")}</Typography>
                <Typography variant={'body1'} textAlign={'center'}>{t("Please enter the verification code sent to:")}</Typography>
                <Typography variant={'h6'} textAlign={'center'} color={'green.main'}>{dataSent?.email}</Typography>

            </Stack>
            <CodeInput codeLength={6} onCodeFilled={handleCodeVerification} />

            <Stack direction={"column"} alignItems={'center'} mb={'15px'}>
            <LoadingButton onClick={handleResendCode} variant="contained" endIcon={<RefreshOutlined/>} loading={loading}>{t("Resend code")}</LoadingButton>
            </Stack>

        </Box>
    </>

}

const UpdatePassword = ({ temporaryToken }) => {
    const { t, i18n } = useTranslation();
    const [passwordUpdated, setPasswordUpdated] = useState(false);
    const navigate = useNavigate();
    const axios = useAxios().instance;
    const schema = z.object({
        password: z.string().min(8, 'Password should be at least 8 characters long'),
    });

    const { handleSubmit, control, formState: { errors } } = useForm({
        resolver: zodResolver(schema),
    });

    const handlePasswordUpdate = async (data) => {
        try {
            data.temporaryToken = `Bearer ${temporaryToken}`;
            const response = await axios.post('/auth/update-password', data);
            if (response.status === 200) {
                setPasswordUpdated(true);
            }
        } catch (error) {

        }
    }

    return passwordUpdated ? <>
        <Stack direction={"column"} alignItems={'center'} mb={'15px'}>
            <Typography variant={'h6'}>{t("Password updated successfully!")}</Typography>
            <Typography variant={'body1'}>{t("You can now log in with your new password.")}</Typography>
        </Stack>
        <Stack direction={"column"} alignItems={'center'} mb={'15px'}>
            <Button variant={'contained'} onClick={() => { navigate("/") }}>{t("Log in")}</Button>
        </Stack>
    </>
        : <form onSubmit={handleSubmit(handlePasswordUpdate)}>
            <Stack spacing={'15px'}>
                <Typography variant={'h4'}textAlign={'center'}>{t("Update Your Password")}</Typography>
                <Typography variant={'body1'} textAlign={'center'}>{t("Please enter your new password. It should be at least 8 characters long.")}</Typography>
                <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <TextField   {...field} type="password" label={t('New Password')} error={!!errors.password} helperText={t(errors.password?.message)} />}
                />
                <LoadingButton type="submit" variant="contained" >{t("Update Password")}</LoadingButton>
            </Stack>
        </form>
}


export default PasswordRecovery;