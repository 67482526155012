import { memo, useEffect } from 'react';

import { connect, useDispatch } from "react-redux";
import NewEntryStepper from '../../components/NewEntryStepper/NewEntryStepper';
import NewClientModal from '../../components/NewClientModal/NewClientModal';
import NavigationBar from "../../components/Navbar/NavigationBar";
import DataTable from "../../components/DataTable/DataTable";
import EditOrderPage from '../../containers/EditOrder/EditOrderPage';
import OrganizationSettings from '../../containers/Settings/OrganizationSettings'
// import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from 'react-redux';
import SearchDialog from '../../components/SearchDialog/SearchDialog';
import { useState } from 'react';
import { Box } from '@mui/material';
import PrintSettings from '../Content/PrintSettings';
import InvoiceCreator from '../InvoiceCreator/InvoiceCreator';
import InvoiceView from '../InvoiceView/InvoiceView';
import QuoteView from 'containers/QuoteView/QuoteView';
import { useTheme } from '@emotion/react';
import ChartsPage from '../Charts/ChartsPage';
import UserSettingsPage from '../UserSettings/UserSettingsPage';
import ServiceCatalog from '../ServiceCatalog/ServiceCatalog';
import { fetchStatuses } from '../../store/data-table';
import InventoryPage from 'containers/Inventory/InventoryPage';
import EditTransferPage from 'containers/Inventory/Transfer/EditTransfer';
import { UserPermissionsContextProvider, useUserPermissions } from 'contexts/UserPermissions';
import ChartsPageV2 from 'containers/Charts/ChartsPage_v2';
// const useStyles = makeStyles((theme) => ({
//   toolbar: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "flex-end",
//     padding: theme.spacing(0, 1),
//     // necessary for content to be below app bar
//     // ...theme.mixins.toolbar,
//   },
//   content: {
//     flexGrow: 1,
//     padding: theme.spacing(0),
//   },
// }));

const Main = (props) => {
  // const classes = useStyles();
  // const theme = useTheme();
  const [searchDialog, setSearchDialog] = useState(false);
  const dispatch = useDispatch();
  const handleOpenSearchDialog = () => {
    setSearchDialog(true);
  }
  const handleCloseSearchDialog = () => {

    setSearchDialog(false)
  }

  const [color, changeColor] = useState("white");
  useEffect(() => {
    //Get statuses
    dispatch(fetchStatuses());
  }, []);

  document.body.style.backgroundColor = "white";
  const searchFieldsBucket = ['id', 'malfunction', 'device', 'customer', 'note', 'resolution', 'imei']
  const [searchInFields, setSearchInFields] = useState(['id', 'malfunction', 'device', 'customer', 'note', 'resolution', 'imei']);

  return (
    <>
      <UserPermissionsContextProvider>
        <NavigationBar openSearchDialog={handleOpenSearchDialog}>
          <SearchDialog open={searchDialog} handleClose={handleCloseSearchDialog} />
          {/* <main > */}
          <Routes>
            <Route path='' element={<DataTable changeColor={changeColor} searchFieldsBucket={searchFieldsBucket} searchInFields={searchInFields} setSearchInFields={setSearchInFields} />} />
            <Route path='/editEntry/:entryId' element={<EditOrderPage token={props.token} changeColor={changeColor} />} />
            <Route path='/viewInvoices/' element={<InvoiceView changeColor={changeColor} />} />
            <Route path='/quotes/' element={<QuoteView changeColor={changeColor} />} />

            <Route path='/service-catalog' element={<ServiceCatalog changeColor={changeColor} />} />
            <Route path='/editEntry/request/:tempEntryId' element={<EditOrderPage token={props.token} changeColor={changeColor} />} />
            <Route path='/invoiceCreator' element={<InvoiceCreator changeColor={changeColor} />} />
            <Route path='/organization' element={<OrganizationSettings token={props.token} changeColor={changeColor} />} />
            <Route path='/printSettings' element={<PrintSettings changeColor={changeColor} />} />
            <Route path='/charts' element={<ChartsPageV2 changeColor={changeColor} />} />
            <Route path='/userSettings' element={<UserSettingsPage changeColor={changeColor} />} />
            <Route path='/inventory' element={<InventoryPage changeColor={changeColor} />} />
            <Route path='/inventory/transfer/:transferId' element={<EditTransferPage changeColor={changeColor} />} />
            <Route path="*" element={<Navigate to="/" replace />} />

          </Routes>
          {/* </main> */}
        </NavigationBar>

        <NewEntryStepper />
        {props.children}
      </UserPermissionsContextProvider>

    </>

  );
};


export default Main;
