// AxiosContext.js
import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useStore } from 'react-redux';
import { authActions } from '../store/auth';
import semver from 'semver';
import ConsoleHelper from 'utils/ConsoleHelper';

const SIX_HOURS_IN_MS = 1000 * 60 * 60 * 6;
const AxiosContext = createContext();
export const useAxios = () => useContext(AxiosContext);

export const AxiosProvider = ({ children }) => {
  const store = useStore();
  const [serverDown, setServerDown] = useState(false);
  const [updateAvailable, setUpdateAvailable] = useState(false);

  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  const healthCheckInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  // Create a BroadcastChannel for communicating across tabs
  const channel = new BroadcastChannel('app-version-channel');

  // Function to check if a reload was triggered recently
  const hasReloadedRecently = () => {
    const reloadTriggeredTime = window.localStorage.getItem('force-reload-triggered-time');
    return reloadTriggeredTime && (Date.now() - parseInt(reloadTriggeredTime)) < SIX_HOURS_IN_MS;
  };

  // Listen for messages from other tabs to trigger a reload
  channel.onmessage = (event) => {
    if (event.data && event.data.action === 'reload') {
      if (!hasReloadedRecently()) {
        console.log('Reloading due to version update');
        window.localStorage.setItem('force-reload-triggered-time', Date.now().toString());
        window.location.reload();
      }
    }
  };

  // Function to set up interceptors
  const setupInterceptors = (instance, healthCheckInstance) => {
    // Request Interceptor
    instance.interceptors.request.use((config) => {
      config.headers.Authorization = store.getState().auth.token;
      config.headers['frontend-app-version'] = process.env.REACT_APP_VERSION;
      return config;
    }, (error) => {
      return Promise.reject(error);
    });

    // Health Check Request Interceptor
    instance.interceptors.request.use(async (config) => {
      try {
        await healthCheckInstance.get('/health-check');
        setServerDown(false);
      } catch (error) {
        if (error.code === 'ECONNREFUSED' || error.message === 'Network Error') {
          setServerDown(true);
        }
      }
      return config;
    }, (error) => {
      return Promise.reject(error);
    });

    // Response Interceptor to handle version updates with 6-hour condition
    healthCheckInstance.interceptors.response.use((response) => {
      const currentVersion = process.env.REACT_APP_VERSION; // Current version of the app
      const liveVersion = response.headers['x-version']; // Live version from the server
      const minVersionRequired = response.headers['x-min-version']; // Minimum version required by the server

      // Check if the server version is newer than the current version
      const isServerVersionNewer = semver.gt(liveVersion, currentVersion);
      let minVersionRequiredMet = undefined;

      if (minVersionRequired) {
        minVersionRequiredMet = semver.gte(currentVersion, minVersionRequired);
      }

      // If the server version is newer and the minimum version required is not met
      if (isServerVersionNewer && !minVersionRequiredMet && minVersionRequiredMet !== undefined) {
        if (!hasReloadedRecently()) {
          // Set the current time in localStorage to track when the reload was triggered
          window.localStorage.setItem('force-reload-triggered-time', Date.now().toString());

          // Broadcast a message to all other tabs to trigger reload
          console.log('Reloading due to version update');
          channel.postMessage({ action: 'reload' });

          // Reload this tab immediately
          window.location.reload();
        }
      }

      // Only update state if the value has changed
      if (isServerVersionNewer !== updateAvailable) {
        setUpdateAvailable(isServerVersionNewer);
      }

      return response;
    }, (error) => {
      return Promise.reject(error);
    });

    // Response Interceptor for authentication and subscription handling
    instance.interceptors.response.use(response => response, (error) => {
      if (error.response && error.response.data) {
        const { message, code } = error.response.data;
        const status = error.response.status;

        if (message === 'Not authenticated') {
          store.dispatch(authActions.logout());
        }

        if (message === 'Token expired, please log in again.') {
          store.dispatch(authActions.logout());
        }

        if (code === "NOT_VERIFIED") {
          store.dispatch(authActions.unverifyUser());
        }

        if (status === 403 && message === "Subscription expired.") {
          console.log("Subscription expired");
          store.dispatch(authActions.updateActiveSubscription(false));
        }
      }
      return Promise.reject(error);
    });
  };

  // Call the setup function to initialize interceptors
  setupInterceptors(instance, healthCheckInstance);

  return (
    <AxiosContext.Provider value={{ instance, serverDown, updateAvailable }}>
      {children}
    </AxiosContext.Provider>
  );
};