import * as React from 'react';
import { Box } from '@mui/system';
import { Chip, Typography, Button, Stack, ListSubheader, Dialog, DialogTitle, DialogContent } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { repairStatuses, repairResolutions, statusColors, repairStatusesId } from '../../utils/repair-statuses-helpers';
import axios from "../../axios-config";
import { styled } from '@mui/material/styles';

//ICONS
import CircleStatus from '@mui/icons-material/Circle';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import ArrowIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import RepairedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DeclinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import IrreproducibleIcon from '@mui/icons-material/HelpOutlineOutlined';
import IrreparableIcon from '@mui/icons-material/PendingOutlined';
import WarrantyIcon from '@mui/icons-material/GppGoodOutlined';

import { useSnackbar } from 'notistack';
import { useTranslation } from "react-i18next";
import { useTheme } from '@emotion/react';
import { set } from 'lodash';

const StatusChip = ({ repairOrderData, onUpdateStatusCallback, isBulk, repairDataBulk }) => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [showConditionalStatuses, setShowConditionalStatuses] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [resolutionDialogOpen, setResolutionDialogOpen] = React.useState(false);
    const [statusIndexSelected, setStatusIndexSelected] = React.useState(null);
    const [toggleReverseStatuses, setToggleReverseStatuses] = React.useState(false);
    const [isUpdatingStatus, setIsUpdatingStatus] = React.useState(false);
    const { t } = useTranslation();
    const theme = useTheme();

    const pooperOpen = Boolean(anchorEl);
    const id = pooperOpen ? 'simple-popover' : undefined;
    const handleClick = (event) => {
        event.stopPropagation();
        if (repairOrderData.isRequest || repairOrderData.deliveredAt) return;
        setAnchorEl(event.currentTarget);
    };


    React.useEffect(() => {
    }, [showConditionalStatuses])
    const updateStatus = async (event) => {
        if (isBulk) {
            handleBulkStatusUpdate(event);
        } else {
            handleOneStatusUpdate(event);
        }
    }

    const handleBulkStatusUpdate = async (event) => {
        event.stopPropagation();
        if (isUpdatingStatus) return;
        setIsUpdatingStatus(true);
        const newStatusId = event.currentTarget.getAttribute("statusindex");
        if (!newStatusId) {
            // console.log("There's no status selected");
            return;
        }


        try {

            if (isResolutionRequired(newStatusId)) {
                const hasResolution = repairOrderData.resolutionTypeId;
                if (!hasResolution) {
                    // enqueueSnackbar(`Please select a resolution first`, { variant: "error" });
                    setStatusIndexSelected(newStatusId);
                    setResolutionDialogOpen(true);
                    setIsUpdatingStatus(false);
                    return;
                }
            }
            const ids = repairDataBulk.map(order => order.id);

            const response = await axios.patch(`/repair-orders/bulkUpdate/status/${newStatusId}`, { orderIds: ids });
            if (response.status === 200) {
                onUpdateStatusCallback(response.data);

                handleClose();
            }
            setIsUpdatingStatus(false);
            return;
        } catch (err) {

            console.log(err);
            if (err.response.data.message) {
                enqueueSnackbar(err.response.data.message, { variant: "error" });
                return;
            }
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        };

        setIsUpdatingStatus(false);
    }
    const isResolutionRequired = (newStatusId) => {
        const statusName = repairStatusesId[newStatusId]?.name;
        return (
            statusName === 'Delivered' ||
            statusName === 'In Delivery' ||
            statusName === 'Ready For Pick Up' ||
            statusName === 'Delivred to Customer'
        );
    }



    const handleOneStatusUpdate = async (event) => {
        event.stopPropagation();
        setIsUpdatingStatus(true);

        const repairOrderId = repairOrderData.id;
        const newStatusId = event.currentTarget.getAttribute("statusindex");
        if (!newStatusId) {
            // console.log("There's no status selected");
            return;
        }

        if (isResolutionRequired(newStatusId)) {
            const hasResolution = repairOrderData.resolutionTypeId;
            if (!hasResolution) {
                // enqueueSnackbar(`Please select a resolution first`, { variant: "error" });
                setStatusIndexSelected(newStatusId);
                setResolutionDialogOpen(true);
                setIsUpdatingStatus(false);
                return;
            }
        }

        try {
            const response = await axios.patch(`/repair-orders/${repairOrderId}/status/${newStatusId}`);
            if (response.status === 200) {
                onUpdateStatusCallback(response.data);
                handleClose();
                enqueueSnackbar(`Status updated succesfully`, { variant: "success" });
            }
        } catch (err) {
            if (err.response?.data?.message) {
                enqueueSnackbar(err.response.data.message, { variant: "error" });
                return;
            }
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        };
        setIsUpdatingStatus(false);
    }



    const handleOneStatusAndResolutionUpdate = async (resolutionType, event) => {
        event.stopPropagation();
        if (isUpdatingStatus) return;
        setIsUpdatingStatus(true);
        const repairOrderId = repairOrderData.id;
        const resolutionIndex = resolutionType.index;

        if (!statusIndexSelected || !resolutionIndex) {
            // console.log("There's no status selected");
            return;
        }

        try {

            const response = await axios.patch(`/repair-orders/${repairOrderId}/status/${statusIndexSelected}`, { resolutionIndex: resolutionIndex });
            if (response.status === 200) {
                onUpdateStatusCallback(response.data);
                handleClose();
                setResolutionDialogOpen(false);
                enqueueSnackbar(`Status updated succesfully`, { variant: "success" });
            }
        } catch (err) {
            console.log(err);
            console.log(err.response, "err.response");
            if (err.response.data.message) {
                enqueueSnackbar(err.response.data.message, { variant: "error" });
                return;
            }
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        };
        setIsUpdatingStatus(false);
    }

    const handleBulkStatusAndResolutionUpdate = async (resolutionType, event) => {
        event.stopPropagation();
        if (isUpdatingStatus) return;
        setIsUpdatingStatus(true);
        const repairOrderId = repairOrderData.id;
        const resolutionIndex = resolutionType.index;

        if (!statusIndexSelected || !resolutionIndex) {
            // console.log("There's no status selected");
            return;
        }

        const ids = repairDataBulk.map(order => order.id);

        try {

            const response = await axios.patch(`/repair-orders/bulkUpdate/status/${statusIndexSelected}`, { orderIds: ids, resolutionIndex: resolutionIndex });
            if (response.status === 200) {

                onUpdateStatusCallback(response.data);
                handleClose();
                setResolutionDialogOpen(false);
                enqueueSnackbar(`Status updated succesfully`, { variant: "success" });
            }
        } catch (err) {
            console.log(err);
            if (err.response.data.message) {
                enqueueSnackbar(err.response.data.message, { variant: "error" });
                return;
            }
            enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        };
        setIsUpdatingStatus(false);
    }

    const handleUpdateWithResolution = (resolutionType, event) => {
        if (isBulk) {
            handleBulkStatusAndResolutionUpdate(resolutionType, event);
        } else {
            handleOneStatusAndResolutionUpdate(resolutionType, event);
        }

    }

    const handleResolutionDialogClose = (event) => {
        event.stopPropagation();
        setResolutionDialogOpen(false);
    }

    const handleClose = (event) => {
        setToggleReverseStatuses(false);
        setAnchorEl(null);
    };

    const arrow = () => {
        if (repairOrderData.deliveredAt) return null;
        if (pooperOpen)
            return <ArrowIcon sx={{ width: '14px', height: '14px', transform: 'rotate(90deg)' }} />
        return <ArrowIcon sx={{ width: '14px', height: '14px', transform: 'rotate(-90deg)' }} />

    }


    const getStatusStyle = (statusName) => {
        let statusStyle = statusColors[statusName];
        if (!statusStyle) {
            statusStyle = ['default'];
        }
        return statusStyle;
    }
    const statusChip = () => {
        const statusStyle = getStatusStyle(repairOrderData.repairOrderStatus.name);
        const statusClass = repairOrderData.repairOrderStatus.name.toLowerCase().split(' ').map((word, index) => index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)).join('');

        let statusToColor = theme.palette.lightGrey.main;

        const statusColor = theme.palette[statusClass];
        if (statusColor) {
            statusToColor = statusColor.main;
        }

        if (isBulk) {
            return <Button onClick={handleClick} variant='contained' color='primary' sx={{ borderRadius: '50px' }}>{t("Change Status")}</Button>
        }
        return <Chip variant={statusStyle.variant} onClick={handleClick} color={statusClass} sx={{ color: statusStyle.fontColor, cursor: 'pointer', textTransform: 'uppercase', fontWeight: '600', fontSize: '0.75rem' }}
            label={
                <Stack maxWidth={'3000px'} direction={'row'} spacing={'2px'} alignItems={'center'}>
                    <Typography variant='body2' fontWeight={500} textTransform={'none'} textAlign={'center'} sx={{ overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'clip' }}>
                        {t(repairOrderData.repairOrderStatus.name)}
                    </Typography>
                    {arrow()}
                </Stack>
            } />
    };

    const Resolutions = () => {
        const keys = Object.keys(repairResolutions);
        return <>
            <Stack direction='row' flexWrap={'wrap'} gap={2}>
                {keys.map((key) =>
                    <Chip color={resolutionIcons[key].color} index={repairResolutions[key].index} icon={resolutionIcons[key].icon} variant={'outlined'} key={repairResolutions[key].index} sx={{ fontWeight: '500' }} label={t(repairResolutions[key].name)} onClick={(event) => handleUpdateWithResolution(repairResolutions[key], event)}
                    />
                )}
            </Stack>
        </>
    }

    const resolutionIcons = {
        Repaired: { icon: <RepairedIcon />, color: 'primary' },
        Declined: { icon: <DeclinedIcon />, color: 'red' },
        Irreparable: { icon: <IrreparableIcon />, color: 'pink' },
        Irreproducible: { icon: <IrreproducibleIcon />, color: 'danube' },
        Warranty: { icon: <WarrantyIcon />, color: 'darkBlue' }
    }

    if (!repairOrderData || !repairOrderData.repairOrderStatus) {
        return null;
    }
    return (
        <React.Fragment>
            {statusChip()}
            {/* <Box onClick={(e) => e.stopPropagation()}> */}
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={pooperOpen}
                    onClick={(e) => e.stopPropagation()}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <Box component={'li'}><Typography variant='overline' color={'darkGrey.main'} ml={1}>{t("New")}</Typography></Box>
                    <MenuItem sx={{ fontSize: '14px', py: '2px' }} statusindex={1} onClick={updateStatus} >
                        <Typography sx={{ fontSize: 'inherit', backgroundColor: theme.palette.inQueue.main, color: 'white', px: '10px', borderRadius: '15px' }}>
                            {t("In Queue")}
                        </Typography>
                    </MenuItem>
                    <Box component={'li'}><Typography variant='overline' color={'darkGrey.main'} ml={1}>{t("Waiting")}</Typography></Box>
                    <MenuItem sx={{ fontSize: '14px', py: '2px' }} statusindex={4} onClick={updateStatus}>
                        <Typography sx={{ fontSize: 'inherit', backgroundColor: theme.palette.onHold.main, color: 'white', px: '10px', borderRadius: '15px' }}>
                            {t("On Hold")}
                        </Typography>
                    </MenuItem>
                    <MenuItem sx={{ fontSize: '14px', py: '2px' }} statusindex={3} onClick={updateStatus}>
                        <Typography sx={{ fontSize: 'inherit', backgroundColor: theme.palette.notifyCustomer.main, color: 'white', px: '10px', borderRadius: '15px' }}>
                            {t("Notify Customer")}
                        </Typography>
                    </MenuItem>

                    <Box component={'li'}><Typography variant='overline' color={'darkGrey.main'} ml={1}>{t("Parts")}</Typography></Box>
                    <MenuItem sx={{ fontSize: '14px', py: '2px' }} statusindex={13} onClick={updateStatus}>
                        <Typography sx={{ fontSize: 'inherit', backgroundColor: theme.palette.orderParts.main, color: 'black', px: '10px', borderRadius: '15px' }}>
                            {t("Order Parts")}
                        </Typography>
                    </MenuItem>
                    <MenuItem sx={{ fontSize: '14px', py: '2px' }} statusindex={6} onClick={updateStatus}>
                        <Typography sx={{ fontSize: 'inherit', backgroundColor: theme.palette.waitingForParts.main, color: 'white', px: '10px', borderRadius: '15px' }}>
                            {t("Waiting For Parts")}
                        </Typography>
                    </MenuItem>

                    <Box component={'li'}><Typography variant='overline' color={'darkGrey.main'} ml={1}>{t("In Progress")}</Typography></Box>
                    <MenuItem sx={{ fontSize: '14px', py: '2px' }} statusindex={2} onClick={updateStatus}>
                        <Typography sx={{ fontSize: 'inherit', backgroundColor: theme.palette.diagnose.main, color: 'black', px: '10px', borderRadius: '15px' }}>
                            {t("Diagnose")}
                        </Typography>
                    </MenuItem>
                    <MenuItem sx={{ fontSize: '14px', py: '2px' }} statusindex={8} onClick={updateStatus}>
                        <Typography sx={{ fontSize: 'inherit', backgroundColor: theme.palette.qualityAssurance.main, color: 'white', px: '10px', borderRadius: '15px' }}>
                            {t("Quality Assurance")}
                        </Typography>
                    </MenuItem>
                    <MenuItem sx={{ fontSize: '14px', py: '2px' }} statusindex={5} onClick={updateStatus}>
                        <Typography sx={{ fontSize: 'inherit', backgroundColor: theme.palette.inRepair.main, color: 'white', px: '10px', borderRadius: '15px' }}>
                            {t("In Repair")}
                        </Typography>
                    </MenuItem>
                    <Box component={'li'}><Typography variant='overline' color={'darkGrey.main'} ml={1}>{t("Done")}</Typography></Box>
                    <MenuItem sx={{ fontSize: '14px', py: '2px' }} statusindex={9} onClick={updateStatus}>
                        <Typography sx={{ fontSize: 'inherit', backgroundColor: theme.palette.readyForPickUp.main, color: 'white', px: '10px', borderRadius: '15px' }}>
                            {t("Ready For Pick Up")}
                        </Typography>
                    </MenuItem>
                    <MenuItem sx={{ fontSize: '14px', py: '2px' }} statusindex={7} onClick={updateStatus}>
                        <Typography sx={{ fontSize: 'inherit', backgroundColor: theme.palette.inDelivery.main, color: 'white', px: '10px', borderRadius: '15px' }}>
                            {t("In Delivery")}
                        </Typography>
                    </MenuItem>
                    <MenuItem sx={{ fontSize: '14px', py: '2px' }} statusindex={12} onClick={updateStatus}>
                        <Typography sx={{ fontSize: 'inherit', backgroundColor: theme.palette.delivered.main, color: 'white', px: '10px', borderRadius: '15px' }}>
                            {t("Delivered")}
                        </Typography>
                    </MenuItem>
                </Menu>
            {/* </Box> */}
            <Dialog open={resolutionDialogOpen} onClose={handleResolutionDialogClose}>
                <DialogTitle>{t("Select a resolution")}</DialogTitle>
                <DialogContent>{t("This status requires a resolution")}
                </DialogContent>
                <DialogContent>
                    <Resolutions />
                </DialogContent>

            </Dialog>
        </React.Fragment>
    )
}

export default React.memo(StatusChip);