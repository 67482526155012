import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, Stack, Popover, TableBody, TextField, Typography, FormControlLabel, FormGroup, Checkbox, IconButton, Tab, TableFooter, Chip, Select, InputLabel, FormControl, MenuItem } from "@mui/material";
import { Table, TableRow, TableHead, TableCell } from "@mui/material";

import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { AddOutlined, ArrowDropDownRounded, CalendarMonthOutlined, CarRentalOutlined, CheckBox, Close, DeleteForeverOutlined, DeleteOutline, GridOnOutlined, LocalShippingOutlined, PlusOne, Remove, RemoveOutlined, TableRowsRounded, WarningRounded } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { set } from "lodash";
import { useAxios } from "contexts/AxiosContext";
import { useSnackbar } from "notistack";
import { Calendar, defaultInputRanges, DefinedRange, createStaticRanges } from "react-date-range";
import * as rdrLocales from 'react-date-range/dist/locale';
import SupplierSearch from "./SupplierSearch";
import ProductSearch from "./ProductSearch";
import StatusChip from "components/StatusChip/StatusChip";
import { LoadingButton } from "@mui/lab";

const AddNewTransferDialog = ({ open, onClose, locations, setLocations, warehouse }) => {
    const { t, i18n } = useTranslation();
    const [products, setProducts] = useState([]);
    const [supplier, setSupplier] = useState(null);
    const [note, setNote] = useState('');
    const [status, setStatus] = useState('not delivered'); // ['not-delivered', 'delivered'
    const [submitting, setSubmitting] = useState(false);
    const [remindOnExpectedDate, setRemindOnExpectedDate] = useState(false);
    const [total, setTotal] = useState(0);
    const axios = useAxios().instance;

    const { enqueueSnackbar } = useSnackbar();
    let dayjs = require('dayjs')


    //Calendar
    const [calendarAnchorEl, setCalendarAnchorEl] = useState(null);
    const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(null);
    const calendarOpen = Boolean(calendarAnchorEl);
    const calendarId = calendarOpen ? 'calendar-popover' : undefined;

    const handleCalendarOpen = (event) => {
        if (status === 'Delivered') return;
        setCalendarAnchorEl(event.currentTarget);
    };

    const handleCalendarClose = () => {
        setCalendarAnchorEl(null);
    };

    const [selectedTime, setSelectedTime] = useState('09:00');

    const hanldeDateSelect = (v) => {
        setExpectedDeliveryDate(dayjs(v).toDate());
        handleCalendarClose();
    }

    const handleAddClick = (e) => {
        e.stopPropagation();
        console.log('Add clicked');
    }

    // For items in the table

    const handleAddProduct = (product) => {

        // if product already exists in the list, quantity is increased by 1
        if (products.find(p => p.id === product.id)) {
            setProducts(products => products.map(p =>
                p.id === product.id ? { ...p, quantity: parseInt(p.quantity) + 1 } : p
            ));
            return;
        }


        const newProduct = {
            ...product,
            quantity: 1,
            costPerUnit: ''
        }

        setProducts([...products, newProduct]);
    }

    const handleSetQuantity = (productId, quantity) => {
        setProducts(products => products.map(product =>
            product.id === productId ? { ...product, quantity } : product
        ));
    }

    const handleSetCost = (productId, costPerUnit) => {
        setProducts(products => products.map(product =>
            product.id === productId ? { ...product, costPerUnit: costPerUnit } : product
        ));
    }

    const handleRemoveProduct = (productId) => {
        setProducts(products => products.filter(product => product.id !== productId));
    }

    const handleDialogClose = () => {
        setProducts([]);
        setSupplier(null);
        onClose();
    }

    useEffect(() => {
        if (products.length > 0) {
            let total = 0;
            products.forEach(product => {
                total += product.costPerUnit * product.quantity;
            });
            setTotal(total);
        } else {
            setTotal(0);
        }
    }, [products]);

    const handleStatusSelect = (e) => {
        setStatus(e.target.value);
    }

    const isFormValid = () => {
        if (!supplier) {
            return false;
        }
        return true;
    }

    console.log(warehouse)

    const handleCreatePosting = async () => {
        try {
            setSubmitting(true);
            const data = {
                supplierId: supplier.id,
                warehouseId: warehouse.id,
                note,
                status,
                remindOnExpectedDate,
                expectedDeliveryDate,
                postingItems: products.map(product => {
                    return {
                        productId: product.id,
                        quantity: product.quantity,
                        costPerUnit: product.costPerUnit
                    }
                })
            };
            console.log(data);
            const response = await axios.post('/inventory/postings', data);
            if (response.status === 200 || response.status === 201) {
                enqueueSnackbar(t('Transfer created successfully'), { variant: "success" });
                handleDialogClose();
            }
            setSubmitting(false);
        } catch (err) {
            console.log(err);
            setSubmitting(false);
            enqueueSnackbar(t('Something went wrong'), { variant: "error" });
        }
    }

    return <Dialog open={Boolean(open)} onClose={handleDialogClose} fullWidth maxWidth={'md'}>
        <DialogTitle variant='h4'>
            {t("Add new transfer")}
        </DialogTitle>
        <DialogContent>
            <Stack spacing={2} >
                <Stack direction={'row'} gap={2} id={'top-form'} sx={{ width: '100%' }} >
                    <Stack spacing={2} id={'form-left-side'} sx={{ width: '100%' }}>
                        <SupplierSearch
                            // sx={{ width: "350px" }}
                            required
                            onOptionSelect={setSupplier}
                            state={supplier} />

                        <Stack direction={'row'} spacing={2}>

                            <TextField
                                fullWidth
                                disabled={status === 'Delivered'}
                                label={t('Expected arrival')}
                                onClick={handleCalendarOpen}
                                // sx={{ width: "350px" }}
                                variant="filled"
                                value={expectedDeliveryDate === null ? t("Not selected") : dayjs(expectedDeliveryDate).format('DD MMM YYYY')}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><CalendarMonthOutlined /></InputAdornment>,
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            {expectedDeliveryDate && <IconButton onClick={(e) => {
                                                e.stopPropagation();
                                                setExpectedDeliveryDate(null)
                                            }}>
                                                <Close />
                                            </IconButton>}
                                            <ArrowDropDownRounded />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Stack>

                        {/* <FormControlLabel
                            control={
                                <Checkbox
                                    checked={remindOnExpectedDate}
                                    onChange={e => setRemindOnExpectedDate(e.target.checked)}
                                />
                            }
                            label={t("Remind me on this date")}
                        /> */}
                    </Stack>
                    <Stack spacing={2} id={"form-right-side"} sx={{ width: '100%' }}>
                        <TextField multiline label={t('Note')} multiline minRows={4.2} fullWidth maxRows={6} variant="filled" />

                    </Stack>
                </Stack>

                <ProductSearch onOptionSelect={handleAddProduct} />
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("Name")}</TableCell>
                            <TableCell>{t("Quantity")}</TableCell>
                            <TableCell>{t("Cost")}</TableCell>
                            <TableCell align="right" >{t("Total")}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.length === 0 && <TableRow>
                            <TableCell colSpan={5} align="center">{t("No products added")}</TableCell>
                        </TableRow>}
                        {products.length > 0 && <>
                            {products.map((item, index) => <ItemRow
                                key={index}
                                item={item}
                                handleSetCost={handleSetCost}
                                handleSetQuantity={handleSetQuantity}
                                handleRemoveProduct={handleRemoveProduct}
                            />)}
                            {/* <ItemRow item={{ name: "Apple iPhone 12 Pro Max 128GB Graphite" }} /> */}
                            <TableRow>
                                <TableCell colSpan={3} align="right"><Typography>{t("Total")}:</Typography></TableCell>
                                <TableCell align="right"><Typography>{total.toFixed(2)}</Typography></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </>}

                    </TableBody>

                </Table>
            </Stack>
            <Popover
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                anchorEl={calendarAnchorEl} open={calendarOpen}
                id={calendarId} onClose={handleCalendarClose}>

                <Calendar
                    locale={rdrLocales[i18n.language]}
                    minDate={dayjs().toDate()}
                    // maxDate={dayjs().endOf('month').toDate()}
                    // ranges={[selectionRange]}
                    // staticRanges={predefinedRanges}
                    onChange={hanldeDateSelect}
                    inputRanges={[{
                        ...defaultInputRanges[0],
                        label: t('Days until now')
                    }]}
                />

            </Popover>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleDialogClose}>{t("Cancel")}</Button>
            <LoadingButton loading={submitting} onClick={handleCreatePosting} variant='contained' disabled={!isFormValid()}>{t("Create posting")}</LoadingButton>
        </DialogActions>
    </Dialog >
}


const ItemRow = ({ item, handleSetCost, handleSetQuantity, handleRemoveProduct }) => {
    const [hover, setHover] = useState(false);
    const { t } = useTranslation();

    const handleSetQuantityInput = (e) => {
        let inputValue = e.target.value;
        let minValue = 0;
        let maxValue = 999;
        let integersOnly = true;
        // Allow only digits and optionally a decimal point
        const isValid = /^[\d.]*$/.test(inputValue);
        if (!isValid) return;

        // Check minimum value for numeric input
        if (minValue && parseFloat(inputValue) < minValue) return;
        if (maxValue && parseFloat(inputValue) > maxValue || inputValue.length > 3) {
            // inputValue = maxValue.toString();
            return
        }

        // Check if integersOnly and the value contains a decimal point
        if (integersOnly && inputValue.includes('.')) return;

        handleSetQuantity(item.id, inputValue); // Use the setValue passed from parent
    }

    const handleSetCostInput = (e) => {
        let inputValue = e.target.value;
        let minValue = 0;
        // Allow only digits and optionally a decimal point with up to two decimal places
        const isValid = /^\d*(\.\d{0,2})?$/.test(inputValue);
        if (!isValid) return;

        // Check minimum value for numeric input
        if (minValue && parseFloat(inputValue) < minValue) return;

        handleSetCost(item.id, inputValue); // Use the setValue passed from parent
    }

    const handleRemove = () => {
        handleRemoveProduct(item.id);
    }


    return <TableRow
        key={item.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
    >
        <TableCell>
            <Typography variant={'body'}>{item.name}</Typography>
        </TableCell>
        <TableCell>
            <TextField
                autoComplete={'off'}
                // error={"test"}
                // helperText="This field is required"
                label={t("Quantity")}
                id="quantity"
                variant="filled"
                size="small"
                value={item.quantity}
                onChange={handleSetQuantityInput}
                placeholder={'0'}
                sx={{ width: '80px' }}
            />
        </TableCell>
        <TableCell>
            <TextField
                fullWidth
                autoComplete={'off'}
                // error={"test"}
                // helperText="This field is required"
                label={t("Cost")}
                id="cost"
                variant="filled"
                size="small"
                value={item.costPerUnit}
                onChange={handleSetCostInput}
                placeholder={'0.00'}
                sx={{ width: '150px' }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">{'$'}</InputAdornment>,
                }} />
        </TableCell>
        <TableCell align="right">
            <Typography variant={'body'}>{(item.costPerUnit * item.quantity).toFixed(2)}</Typography>
        </TableCell>
        <TableCell>
            <IconButton onClick={handleRemove} sx={{ color: hover ? "inherit" : "transparent" }} >
                <DeleteOutline />
            </IconButton>
        </TableCell>
    </TableRow>

}



export default AddNewTransferDialog;

