import {
    FormControl,
    CardContent,
    TextField,
    Link,
    Button,
    Grid,
    Stack,
    FormControlLabel,
    Checkbox,
    Box,
    Alert,
} from "@mui/material";
import Typography from "@mui/material/Typography";

import { useAxios } from "contexts/AxiosContext";

import { useState, useRef } from "react";
import { useSnackbar } from "notistack";
import Logo from "../../images/dalvado-logo.png";
import { set } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { authActions } from "../../store/auth";
import { useDispatch } from "react-redux";
import { CheckCircleOutline, RefreshOutlined, WarningAmberOutlined } from "@mui/icons-material";
import ConsoleHelper from "../../utils/ConsoleHelper";
import { LoadingButton } from "@mui/lab";


const VerifyUser = ({ tempLoginData, setTempLoginData, handleSuccessfullVerification }) => {
    const navigate = useNavigate();
    const axios = useAxios().instance;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [code, setCode] = useState(["", "", "", "", "", ""]);
    const inputs = useRef([]);
    const [resendLoading, setResendLoading] = useState(false);
    const [codeResent, setCodeResent] = useState(false);
    const [verifyingCode, setVerifyingCode] = useState(false);
    useEffect(() => {
        inputs.current[0].focus();
        try {
            const checkVerificationStatus = async () => {
                try {
                    // const response = await axios.get('/auth/is-verified');
                    // if (response.status === 200) {
                    //     const user = response.data;
                    //     if (user.verified) {
                    //         ConsoleHelper("User is verified");
                    //         dispatch(authActions.verifyUser());
                    //         navigate('/');
                    //     }
                    // }
                } catch (err) {
                    console.log("Error verifying user", err);
                }
            }
            checkVerificationStatus();

        }
        catch (err) {
            console.log("Error getting user verification status", err);
        }

    }, []);

    useEffect(() => {
        if (showErrorAlert) {
            setShowErrorAlert(false);
        }

        if (code.join('').length === 6) {
            const verifyUser = async () => {
                try {
                    setVerifyingCode(true);
                    ConsoleHelper(tempLoginData);
                    const response = await axios.post('/auth/verify-user', { code: code.join('') });
                    if (response.status === 200) {
                        dispatch(authActions.verifyUser());

                    }
                } catch (err) {
                    enqueueSnackbar(err.response.data.message || "Something went wrong!", { variant: "error" });
                    setShowErrorAlert(true);
                    // enqueueSnackbar(err.response.data.message, { variant: "error" });
                } finally {
                    setVerifyingCode(false);
                }
            }
            verifyUser();
        }
    }, [code]);



    const handleBackButton = () => {
        dispatch(authActions.logout());
    }

    const dispatchLogin = (data) => {
        dispatch(authActions.login(data));
    }


    const focusNext = (index, value) => {
        let newCode = [...code];
        let i = index;

        // Remove special characters and spaces, convert to uppercase
        value = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
        console.log(`focusNext :${index},${value}`);
        if (value === '') {
            // Delete operation
            newCode[index] = '';
            setCode(newCode);
            if (index > 0) {
                inputs.current[index - 1].focus();
            }
            return;
        }

        for (let char of value) {
            if (i >= code.length) break;
            newCode[i] = char;
            i++;
        }

        setCode(newCode);

        if (i < code.length) {
            inputs.current[i].focus();
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        // Remove special characters and spaces, convert to uppercase
        const pastedData = e.clipboardData
            .getData('text/plain')
            .replace(/[^a-zA-Z0-9]/g, '')
            .toUpperCase();

        focusNext(0, pastedData);
    };

    const handleOnInput = (index, e) => {
        if (code[index] && e.target.value.length > 1 && index < code.length - 1) {
            inputs.current[index + 1].focus();
            //get the next character of e.target.value
            const nextChar = e.target.value.charAt(1);
            focusNext(index + 1, nextChar);
            return;
        }
        focusNext(index, e.target.value);
    };
    const handleKeyDown = (index, e) => {
        const { key } = e;
        if (key === "Backspace") {
            let newCode = [...code];

            // Empty current and move focus to the previous if it's not the first input
            if (code[index] === '' && index > 0) {
                newCode[index - 1] = '';
                setCode(newCode);
                inputs.current[index - 1].focus();
            }

            // Empty current if it's the first input
            if (code[index] === '' && index === 0) {
                newCode[index] = '';
                setCode(newCode);
            }
        }
    };

    const handleResendCode = async () => {
        setResendLoading(true);
        try {
            // const tempAxiosInstance = axios.create({
            //     baseURL: process.env.REACT_APP_BASE_URL,
            //     headers: { Authorization: `Bearer ${tempLoginData.token}` }
            // });
            console.log(axios);
            const response = await axios.post('/auth/resend-signup-verification-code');
            console.log(response)
            if (response.status === 200) {
                setCodeResent(true);
                setTimeout(() => setCodeResent(false), 5000); // Hide the message after 5 seconds
            }
        } catch (err) {
            console.log("Error resending verification code", err);
            enqueueSnackbar(err.response.data.message || "Something went wrong!", { variant: "error" });
        }
        setResendLoading(false);
    }

    return (
        <Stack alignItems={'center'}>
            <Typography align="center" variant="h5" mb={2}>
                <span>{t("Verify your email")}</span>
            </Typography>
            {/* check the spam folder or resend code */}
            {/* <Typography align="center" variant="body1" mb={2}>
                <span>{t("Please check your email for the verification code.")}</span>
            </Typography> */}
            <Typography align="center" variant="body1" mb={2}>
                <span>{t("If you don't see the email, please check your spam folder.")}</span>
            </Typography>
            {showErrorAlert && <Alert severity="error" sx={{ border: '1px solid rgba(0,0,0,0.25)', mb: '15px' }} icon={<WarningAmberOutlined />}>
                <Stack alignItems={'flex-start'}>
                    <Typography fontWeight={500} variant={'p'}>{t("Verification failed!")}</Typography>
                    <Typography fontWeight={400} variant={'p'}>{t("Code is not valid.")}</Typography>
                </Stack>
            </Alert>}
            {codeResent && <Alert severity="success" sx={{ border: '1px solid rgba(0,0,0,0.25)', mb: '15px' }} icon={<CheckCircleOutline />}>
                <Stack alignItems={'flex-start'}>
                    <Typography fontWeight={500} variant={'p'}>{t("A new code has been sent to your email.")}</Typography>
                </Stack>
            </Alert>}

            <Grid container spacing={2} justifyContent="center" mb={2}>
                {code.map((digit, index) => (
                    <Grid item key={index}>
                        <TextField
                            disabled={verifyingCode}
                            inputRef={(ref) => (inputs.current[index] = ref)}
                            variant="outlined"
                            value={digit}
                            inputProps={{
                                style: {
                                    width: '2ch',
                                    textAlign: 'center',
                                    letterSpacing: '0.5ch',
                                    backgroundColor: verifyingCode ? 'rgba(0, 0, 0, 0.12)' : 'inherit', // change color when verifying

                                },
                                maxLength: 2,
                            }}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            onInput={(e) => { handleOnInput(index, e) }}
                            // onChange={(e) => focusNext(index, e.target.value)}
                            onPaste={handlePaste}
                        />
                    </Grid>
                ))}
            </Grid>
            <Stack alignItems={'center'} direction={'column'} mb={2}>
                <LoadingButton onClick={handleResendCode} variant="contained" endIcon={<RefreshOutlined />} loading={resendLoading || verifyingCode}>{t("Resend code")}</LoadingButton>
                <Button variant="text" onClick={handleBackButton}>{t("Back")}</Button>
            </Stack>
        </Stack>
    );
};
export default VerifyUser;
