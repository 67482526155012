// First we need to import axios.js
import axios from 'axios';
import { authActions } from './store/auth'
import ConsoleHelper from './utils/ConsoleHelper';

let store
export const injectStore = _store => {
  store = _store
}

// Next we make an 'instance' of it
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,

});

instance.interceptors.request.use(function (config) {
  config.headers.Authorization = store.getState().auth.token;
  return config;
});

// instance.interceptors.response.use(function (response) {
//   const currentVersion = process.env.REACT_APP_VERSION;
//   const liveVersion = response.headers['x-version'];
//   let semver = require('semver');
//   // ConsoleHelper(currentVersion,'currentVersion');
//   // ConsoleHelper(liveVersion,'liveVersion');


//   const isServerVersionNewer = semver.gt(liveVersion, currentVersion);
//   if (isServerVersionNewer) {
//     ConsoleHelper(isServerVersionNewer, 'server is newer')

//     window.localStorage.setItem('version-update-needed', 'true'); // set version update item so can refresh app later
//   };
//   return response; // continue with response
// });

instance.interceptors.response.use((res) => res, async (err) => {

  if (err.response && err.response.data && err.response.data.message === 'Token expired, please log in again.') {
    store.dispatch(authActions.logout())
  }

  return Promise.reject(err);
})


export default instance;