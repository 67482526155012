import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth';
import newEntryModalReducer from './new-entry-modal';
import searchDialogReducers from './search';
import dataTableReducers from './data-table';
import newCustomerModalReducer from './new-customer-modal';
import refreshTokenMiddleware from './middleware/refersh-token-middleware';
import generalReducer from './general'
import axios from '../axios-config';

const store = configureStore({
   reducer: {
      newEntryModal: newEntryModalReducer,
      auth: authReducer,
      searchDialog: searchDialogReducers,
      dataTable: dataTableReducers,
      newClientModal: newCustomerModalReducer,
      general: generalReducer
   },
   // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(refreshTokenMiddleware)
});





export default store;