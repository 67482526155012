import {
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  CardContent,
  TextField,
  OutlinedInput,
  InputAdornment,
  Link,
  Button,
  Grid,
  Alert,
  Divider,
  Stack,
  Typography,

} from "@mui/material";
import { useEffect, useState } from "react";
import { authActions } from "../../../store/auth";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { string, z } from "zod";

import { Link as RouterLink } from 'react-router-dom';

import dayjs from "dayjs";
import VerifyUser from "../../VerifyUser/VerifyUser";
import { LoadingButton } from "@mui/lab";
import ConsoleHelper from "utils/ConsoleHelper";
import { set } from "lodash";
import { WarningOutlined } from "@mui/icons-material";
import { useAxios } from 'contexts/AxiosContext';


const LoginForm = (props) => {
  const { instance, serverDown } = useAxios();
  const axios = instance;
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { verified } = useParams();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailedAlert, setShowFailedAlert] = useState(false);
  const [tempLoginData, setTempLoginData] = useState(null);
  const [loginFailed, setLoginFailed] = useState(false);
  const schema = z.object({
    email: string().email({ message: "Required" }),
    password: string().min(1, { message: "Required" })
  })
  const { handleSubmit, control, watch, formState: { isSubmitting, isDirty, isValid, errors } } = useForm({ resolver: zodResolver(schema) });

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // USED FOR WHEN THE USER DOES THE VERIFICATION 
  useEffect(() => {
    if (verified === "success") {
      setShowSuccessAlert(true);
    } else if (verified === "failed") {
      setShowFailedAlert(true);
    }
  }, [])



  const dispatchLogin = (data) => {
    dispatch(authActions.login(data));
  }

  const updateLanguage = async (data) => {
    const language = data.language;
    if (language === 'es' || language === 'en') {
      i18n.changeLanguage(language);
    }
  }

  const handleLogin = async (data) => {

    try {
      const response = await axios.post(`/auth/login`, data)
      if (response.status !== 200 || !response.data.token) {
        return;
      }
      ConsoleHelper(JSON.stringify(response.data));


      dispatchLogin(response.data);
      updateLanguage(response.data);

      if (location.state?.from) {
        navigate(location.state.from);
        return;
      }
      navigate('/');
    } catch (err) {
      ConsoleHelper(err);
      if (err.response && err.response.status === 401) {
        // enqueueSnackbar(t("Invalid username or password"), { variant: "error" });
        setLoginFailed(true);
        setTimeout(() => setLoginFailed(false), 5000); // Hide the message after 5 seconds
      } else {
        console.log(err)
      }
    }
  };


  return (
    <CardContent sx={{ minWidth: 'xs', maxWidth: 'xs' }}>
      {showSuccessAlert ?
        <Alert onClose={() => { setShowSuccessAlert(false) }}><span>{t("Verification successfully!")}</span></Alert> : null}
      {showFailedAlert ?
        <Alert onClose={() => { setShowFailedAlert(false) }} severity="error"><span>{t("Verification failed!")}</span></Alert> : null}
      {loginFailed && <Alert severity="error" sx={{ border: '1px solid rgba(0,0,0,0.25)', mb: '15px' }} icon={<WarningOutlined />}>
        <Stack alignItems={'flex-start'}>
          <Typography fontWeight={500} variant={'p'}>{t("User or password incorrect.")}</Typography>
        </Stack>
      </Alert>}
      <form onSubmit={handleSubmit(handleLogin)} noValidate>
        <FormControl fullWidth margin="normal" variant="outlined">
          <Controller
            name="email"
            control={control}
            defaultValue={''}
            render={({ field }) => <TextField
              {...field}
              required
              // onChange={onInput}
              label={t("Email")}
              // id="email"
              type="email"
              variant="filled"
              size="small"
              error={!!errors.email}
              helperText={t(errors.email?.message)}
            />}
          />
        </FormControl>
        <FormControl fullWidth margin="normal" variant="outlined">
          <Controller
            name="password"
            control={control}
            defaultValue={''}
            render={({ field }) => <TextField
              {...field}
              required
              label={t("Password")}
              // id="password"
              variant="filled"
              size="small"
              type="password"
              error={!!errors.password}
              helperText={t(errors.password?.message)}
            />}
          />
        </FormControl>
        <Grid container item justifyContent="center" style={{ marginTop: "15px" }}>

          <LoadingButton
            variant="contained"
            color="primary"
            disableElevation
            fullWidth
            type="submit"
            loading={isSubmitting}

          >
            {t('Log in')}
          </LoadingButton>
        </Grid>
        {!process.env.REACT_APP_COSTI_LOCK && <Grid
          container
          item
          justifyContent="center"
          style={{ marginTop: "25px" }}
        >
          {/* <span>{t("Don't have an account?")}</span> */}
          <RouterLink to={'/auth/register'}>
            <Button variant="text">
              {t("or Register")}
            </Button>
            {/* <Link
              component="button"
              variant="body2"
              style={{ marginLeft: "10px" }}
            >
              <span>{t('or Sign up')}</span>
            </Link> */}
          </RouterLink>

        </Grid>}
        {!process.env.REACT_APP_COSTI_LOCK && <Grid container item justifyContent="center" style={{ marginTop: "15px" }}>
          {/* <span>{t("Forgot your password?")}</span>
          <Link
            component="button"
            variant="body2"
            style={{ marginLeft: "10px" }}
          >
            <span>{t('Reset it')}</span>
          </Link> */}
          <Divider style={{ marginTop: "25px" }} />
          <RouterLink to={'/auth/recover-password'}>
            <Button variant="text" color="darkBlue">
              {t("forgot password?")}
            </Button>
            {/* <Link
              component="button"
              variant="body2"
              style={{ marginLeft: "10px" }}
            >
              <span>{t('or Sign up')}</span>
            </Link> */}
          </RouterLink>
        </Grid>}
      </form>
    </CardContent>
  );
};


export default LoginForm;
