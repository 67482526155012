import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { string, z } from 'zod'
import { set } from 'lodash'
import { useAxios } from 'contexts/AxiosContext'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import ImageUpload from 'components/ImageUpload/ImageUpload'
import SelectLocationDialog from '../SelectLocation'
import { Add, Close } from '@mui/icons-material'
import { styled } from '@mui/material/styles';
import ImageUploadButton from 'common/ImageUploadButton'
import { LoadingButton } from '@mui/lab'
import { useSelector } from 'react-redux'
import { getCurrencySymbol } from 'utils/currency'

const EditProductDialog = ({ open, onClose, product, setProduct, warehouse, onProductUpdated }) => {
    const { t } = useTranslation()
    const axios = useAxios().instance;
    const { enqueueSnackbar } = useSnackbar()

    const [selectLocationDialog, setSelectLocationDialog] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageIsLoading, setImageIsLoading] = useState(false);
    const [initialProduct, setInitialProduct] = useState(null);
    const schema = z.object({
        name: z.string().min(1, { message: "Required" }),
        description: z.string().optional(),
        sku: z.string().optional(),
        initialQuantityInStock: z.string().regex(/^\d*$/, 'Must be an integer'),
        barcode: z.string().optional(),
    })
    const { handleSubmit, control, watch, formState: { isSubmitting, isDirty, isValid, errors } } = useForm({ resolver: zodResolver(schema) });

    const currency = useSelector((state) => state.auth.currency);


    useEffect(() => {
        if (open) {
            setInitialProduct(product);
            if (product && product?.productImages.length > 0) {
                setSelectedImage(product.productImages[0]);
            }
        } else {
            setInitialProduct(null);
            setSelectedImage(null);
        }
    }, [open])


    const handleAddProduct = async (data) => {

    }

    const handleNewProductDialogClose = () => {
        onClose()
    }



    const handleLocationSelectOpen = (e) => {
        setSelectLocationDialog(true);
    }

    const handleOnLocationSelect = async (location) => {
        setSelectLocationDialog(false);
        setSelectedLocation(location);
        if (location) {
            setProduct({ ...product, inventoryLocation: location });
        } else {
            setProduct({ ...product, inventoryLocation: null });
        }
        try {
            if (!warehouse) {
                enqueueSnackbar('No warehouse selected', { variant: 'error' });
                return;
            }
            const response = await axios.patch(`/inventory/products/${product.id}/warehouse/${warehouse.id}/location/${location?.id}`);
            if (response.status === 200) {
                enqueueSnackbar('Location updated', { variant: 'success' });
                onProductUpdated();
            }
        }
        catch (error) {
            console.log(error);
            enqueueSnackbar('Error updating location', { variant: 'error' });
        }

    }


    const handleImageSelect = async (imageFile) => {
        try {
            setImageIsLoading(true);
            const formData = new FormData();

            // Append the file under the 'productImage' key
            // Ensure selectedImage is the File object you want to upload
            if (!imageFile) {
                enqueueSnackbar('No image selected', { variant: 'error' });
                return;
            }
            formData.append('productImage', imageFile);
            const response = await axios.post(`/inventory/products/${product.id}/images`, formData);
            if (response.status === 201) {
                enqueueSnackbar('Image uploaded', { variant: 'success' });
                // setSelectedImageUrl(`${process.env.REACT_APP_API_URL}${response.data.imageUrl}`);
                setSelectedImage(response.data);
                onProductUpdated();
            }
        }
        catch (error) {
            console.log(error)
            enqueueSnackbar('Error uploading image', { variant: 'error' });
            return;
        } finally {
            setImageIsLoading(false);
        }
    };

    const handleImageRemove = async () => {
        if (!selectedImage) return;
        const initialState = selectedImage;
        setSelectedImage(null);
        try {
            const response = await axios.delete(`/inventory/products/${product.id}/images/${initialState.id}`);
            if (response.status === 200) {
                enqueueSnackbar(t('Image removed'), { variant: 'success' });
                // setProduct({ ...product, productImages: [] });
                onProductUpdated();
            }
        }
        catch (error) {
            console.log(error);
            enqueueSnackbar('Error removing image', { variant: 'error' });
            setSelectedImage(initialState);
        }

    }

    const allowOnlyNumber = (value) => {
        return value.replace(/[^0-9]/g, '')
    }

    const handleNameChange = (e) => {
        setProduct({ ...product, name: e.target.value });
    }

    const handleProductUpdate = async () => {
        try {
            // if there is no change in the product, do not make the API call
            if (JSON.stringify(product) === JSON.stringify(initialProduct)) return;
            const response = await axios.patch(`/inventory/products/${product.id}`, product);
            if (response.status === 200) {
                enqueueSnackbar('Product updated', { variant: 'success' });
                onProductUpdated();
                setInitialProduct(product);
            }
        }
        catch (error) {
            console.log(error);
            enqueueSnackbar('Error updating product', { variant: 'error' });
        }
    }
    const handleCostChange = (e) => {
        let value = e.target.value;
        value = allowOnlyNumber(value);
        setProduct({ ...product, cost: value });
    }


    if (!product) return null;
    let imageURL = selectedImage ? `${process.env.REACT_APP_API_URL}${selectedImage.imageUrl}` : null;

    return <Dialog open={open} onClose={handleNewProductDialogClose} fullWidth maxWidth={'sm'}>
        <form onSubmit={handleSubmit(handleAddProduct)} noValidate>
            <DialogTitle variant='h4'>
                {t("Edit product")}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Stack direction={'row'} alignItems={'center'} spacing={2} py={'10px'}>
                        <ImageUploadButton onImageSelected={handleImageSelect} imageUrl={imageURL} onImageRemoved={handleImageRemove} isLoading={imageIsLoading} />
                        <Stack>
                            {/* <Typography variant='body2'>Up to 500 KB per file</Typography> */}
                            <Typography variant='body2'>{t("Product image")}</Typography>
                            <Typography variant='body2'>(PNG, JPG)</Typography>
                        </Stack>
                    </Stack>
                    <TextField
                        value={product.name}
                        onChange={handleNameChange}
                        label={t("Name")}
                        type="name"
                        variant="filled"
                        size="small"
                        error={!!errors.name}
                        helperText={t(errors.name?.message)}
                        onBlur={handleProductUpdate}
                    />
                    <TextField
                        label={t("Description")}
                        type='text'
                        variant="filled"
                        size="small"
                        multiline
                        maxRows={3}
                        minRows={3}
                        value={product.description}
                        onChange={(e) => setProduct({ ...product, description: e.target.value })}
                        onBlur={handleProductUpdate}

                    />
                    <TextField
                        label={t("SKU")}
                        type='text'
                        variant="filled"
                        size="small"
                        value={product.sku}
                        onChange={(e) => setProduct({ ...product, sku: e.target.value })}
                        onBlur={handleProductUpdate}

                    />
                    <TextField
                        label={t("Barcode")}
                        type='text'
                        variant="filled"
                        size="small"
                        value={product.barcode}
                        onChange={(e) => setProduct({ ...product, barcode: e.target.value })}
                        onBlur={handleProductUpdate}
                    />
                    <TextField
                        label={t("Cost")}
                        type='text'
                        variant="filled"
                        size="small"
                        value={product.cost}
                        onChange={handleCostChange}
                        onBlur={handleProductUpdate}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
                        }}
                    />
                    <Divider />
                    <FormControl>
                        <InputLabel variant='filled' id="demo-simple-select-label">{t("Warehouse")}</InputLabel>
                        <Select
                            variant='filled'
                            readOnly
                            size='small'
                            labelId="warehosue-select-label"
                            id="warehouse-simple-select"
                            value={warehouse?.name}
                            label="Warehouse"
                        // onChange={handleChange}
                        >
                            <MenuItem value={warehouse?.name}>{warehouse?.name}</MenuItem>
                        </Select>
                    </FormControl>
                    {warehouse && <>
                        <TextField
                            fullWidth
                            label={t('Location')}
                            onClick={handleLocationSelectOpen}
                            value={product?.inventoryLocation?.name ? product.inventoryLocation.name : ''}
                            // sx={{ width: "350px" }}
                            variant="filled"
                            // value={!product.location?.fullPath ? t("Not selected") : "location selected"}
                            InputProps={{

                                // startAdornment: <InputAdornment position="start"><CalendarMonthOutlined /></InputAdornment>,
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        {product?.inventoryLocation?.name && <IconButton onClick={(e) => {
                                            e.stopPropagation();
                                            handleOnLocationSelect(null)
                                        }}>
                                            <Close />
                                        </IconButton>}
                                        {/* <ArrowDropDownRounded /> */}
                                    </InputAdornment>
                                )
                            }}
                        />
                        <SelectLocationDialog open={selectLocationDialog} warehouse={warehouse} onClose={() => setSelectLocationDialog(false)} onLocationSelect={handleOnLocationSelect} onLocationChange={onProductUpdated} />
                    </>
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleNewProductDialogClose}>{t("Close")}</Button>
                {/* <LoadingButton loading={isSubmitting} type='submit' variant='contained'>{t("Add")}</LoadingButton> */}
            </DialogActions>
        </form>
    </Dialog >
}

export default EditProductDialog