import { AddOutlined, ArrowForwardIosOutlined, ArrowForwardOutlined } from '@mui/icons-material';
import {
    Typography, TableContainer, Paper, Table, TableHead, TableRow,
    TableCell, TableBody, Stack, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, DialogContentText
} from '@mui/material'
import { useAxios } from 'contexts/AxiosContext';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import ConsoleHelper from 'utils/ConsoleHelper';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { string, z } from 'zod';
import { set } from 'lodash';

const Suppliers = () => {
    const [suppliers, setSuppliers] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const axios = useAxios().instance;

    const schema = z.object({
        name: z.string().min(1, { message: "Required" }),
        email: z.string().email({ message: "Invalid email" }).or(z.literal('')),
        address: z.string().optional(),
    })
    const { handleSubmit, control, watch, formState: { isSubmitting, isDirty, isValid, errors } } = useForm({ resolver: zodResolver(schema) });



    const fetchSuppliers = async () => {
        try {
            // Make API call or database query to fetch inventory data
            const response = await axios.get('/inventory/suppliers');
            if (response.status === 200) {

                const data = await response.data;
                ConsoleHelper(data);
                setSuppliers(data);
            }
        } catch (error) {
            console.error('Error fetching inventory data:', error);
        }
    }

    useEffect(() => {
        // Fetch inventory data from API
        fetchSuppliers();

    }, []);

    const handleDialogClose = () => {
        setDialogOpen(false);
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    }

    const handleAddSupplier = async (data) => {
        ConsoleHelper(data);
        try {
            const response = await axios.post('/inventory/suppliers', data);
            const responseData = await response.data;
            ConsoleHelper(responseData);
            fetchSuppliers();
            handleDialogClose();
        }
        catch (err) {
            console.log(err);
        }

    }

    return <>
        <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant='body'>Manage your provders</Typography>
            <Button variant='contained' color='primary' startIcon={<AddOutlined />} onClick={handleDialogOpen} >Add new</Button>
        </Stack>
        <TableContainer sx={{ my: '15px' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Ref. ID</TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Email</TableCell>
                        <TableCell align="left">Address</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {suppliers.map((item) => (
                        <SuppliersTableRow key={item.id} item={item} />
                    ))}

                    {/* {Array.from({ length: 5 }, (_, i) => (
                        <SuppliersTableRow key={i} item={{
                            id: 1,
                            refId: '312',
                            name: 'David S.L.',
                            address: "Calle de la Rosa, 12, 28012 Madrid",
                            email: 'some-email@gmail.com',
                            status: 'Active',
                        }} />
                    ))} */}
                    
                </TableBody>
            </Table>
        </TableContainer>
        <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth={'xs'}>
            <form onSubmit={handleSubmit(handleAddSupplier)} noValidate>
                <DialogTitle variant='h4'>
                    {t("Add new provider")}
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        {/* <DialogContentText>
                        {t("Add new provider")}
                    </DialogContentText> */}
                        <Controller
                            name="name"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => <TextField
                                {...field}
                                required
                                label={t("Name")}
                                type="name"
                                variant="filled"
                                size="small"
                                error={!!errors.name}
                                helperText={t(errors.name?.message)}
                            />}
                        />
                        <Controller
                            name="email"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => <TextField
                                {...field}
                                label={t("Email")}
                                type="email"
                                variant="filled"
                                size="small"
                                error={!!errors.email}
                                helperText={t(errors.email?.message)}
                            />}
                        />
                        <Controller
                            name="address"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => <TextField
                                {...field}
                                // onChange={onInput}
                                label={t("Address")}
                                type="address"
                                variant="filled"
                                size="small"
                                error={!!errors.address}
                                helperText={t(errors.address?.message)}
                            />}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>{t("Close")}</Button>
                    <Button type='submit'>{t("Add")}</Button>
                </DialogActions>
            </form>
        </Dialog >
    </>
}

const SuppliersTableRow = ({ item }) => {
    const [hover, setHover] = useState(false);

    return (
        <TableRow
            key={item.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <TableCell component="th" scope="row">
                {item.id}
            </TableCell>
            <TableCell align="left">{item.name}</TableCell>
            <TableCell align="left">{item.email}</TableCell>
            <TableCell align="left">{item.address}</TableCell>

            <TableCell align="right">{item.status}</TableCell>
            <TableCell align="right">
            <ArrowForwardIosOutlined sx={{ fontSize: '15px', color: hover ? 'inherit' : 'transparent' }} />
            </TableCell>
        </TableRow>
    );
};
export default Suppliers;