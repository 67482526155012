import React from "react";
import List from "@mui/material/List";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import { Avatar, ListItem, Typography } from "@mui/material";
import Notification from "./Notification";
import UpdateNotificaiton from './NotificationTypes/UpdateNotificaiton'
import CommentNotification from './NotificationTypes/CommentNotification'
import ReminderNotification from './NotificationTypes/ReminderNotification'

import ParetnerRequestNotification from "./NotificationTypes/PartnerRequestNotification";
import ServiceNotification from "./NotificationTypes/ServiceNotification";
import ServiceAddedNotification from "./NotificationTypes/ServiceAddedNotification";
import { useTranslation } from "react-i18next";
import { useAxios } from "contexts/AxiosContext";
import RepairUpdateNotification from "./NotificationTypes/RepairUpdateNotification";
import NotificationRemoveAccepted from "./NotificationTypes/NotificationRemoveAccepted";
import RepairRequestNotification from "./NotificationTypes/RepairRequestNotification";


const Type = {
  SYSTEM: 'SYSTEM',
  USER_ACTIVITY: 'USER_ACTIVITY',
  TRANSACTIONAL: 'TRANSACTIONAL',
  REMINDER: 'REMINDER',
  PROMOTIONAL: 'PROMOTIONAL',
  ADMINISTRATIVE: 'ADMINISTRATIVE',
  PARTNER_REQUEST: "PARTNER_REQUEST",
  SERVICE_ACCEPTED: "SERVICE_ACCEPTED",
  SERVICE_DECLINED: "SERVICE_DECLINED",
  SERVICE_REMOVE_ACCEPTED: "SERVICE_REMOVE_ACCEPTED",
  SERVICE_ADDED: "SERVICE_ADDED",
  NEW_COMMENT_FROM_PARTNER: "NEW_COMMENT_FROM_PARTNER",
  REPAIR_UPDATED: "REPAIR_UPDATED",
  REPAIR_REQUEST: "REPAIR_REQUEST",
};

function NotificationCenter(props) {
  const { open, selectedValue, onClose } = props;
  const notifications = [...props.notifications];
  const { t } = useTranslation();
  const axios = useAxios().instance;
  const handleClose = () => {
    onClose(selectedValue);
  };
  React.useEffect(async () => {
    if (!open) return;
    const unSeenNotificationIds = [];
    for (let i = 0; i < notifications.length; i++) {
      if (notifications[i].isSeen === false) {
        unSeenNotificationIds.push(notifications[i].id);
      }
    }
    if (unSeenNotificationIds.length === 0) return;
    await axios.patch('users/notifications/markAsSeen', { notificationIds: unSeenNotificationIds });
  }, [open])

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      hideBackdrop={true}
      PaperProps={{
        sx: {
          position: "fixed",
          top: 60,
          right: 190,
          m: 0,
          width: 350,
          height: 280,
          '@media screen and (max-width: 780px)': {
            right: 10,
          },
        },

      }}
    >
      <Typography sx={{ padding: "10px" }}>{t("Notifications")}</Typography>
      <Divider />
      <List sx={{ pt: 0, overflow: 'auto' }}>
        {!notifications || notifications.length === 0 ? <ListItem>{t("There are no notifications...")}</ListItem> : null}
        {notifications &&
          notifications
            .sort(function (a, b) {
              return new Date(b.createdAt) - new Date(a.createdAt);
            })
            .map((notification) => {
              switch (notification.type) {
                case "PARTNER_REQUEST": {
                  return <ParetnerRequestNotification key={notification.id} notification={notification} handleClose={onClose} />;
                }
                case "COMMENT": {
                  return <CommentNotification key={notification.id} notification={notification} handleClose={onClose} />;
                }
                case "REMINDER": {
                  return <ReminderNotification key={notification.id} notification={notification} handleClose={onClose} />;
                }
                case "UPDATE": {
                  return <UpdateNotificaiton key={notification.id} notification={notification} handleClose={onClose} />;
                }
                case "SERVICE_ACCEPTED": {
                  return <ServiceNotification key={notification.id} notification={notification} handleClose={onClose} />;
                }
                case "SERVICE_DECLINED": {
                  return <ServiceNotification key={notification.id} notification={notification} handleClose={onClose} />;
                }
                case "SERVICE_ADDED": {
                  return <ServiceAddedNotification key={notification.id} notification={notification} handleClose={onClose} />;
                }
                case "NEW_COMMENT_FROM_PARTNER": {
                  return <CommentNotification key={notification.id} notification={notification} handleClose={onClose} />;
                }
                case "ORDER_UPDATE": {
                  return <UpdateNotificaiton key={notification.id} notification={notification} handleClose={onClose} />;
                }
                case Type.REPAIR_UPDATED: {
                  return <RepairUpdateNotification key={notification.id} notification={notification} handleClose={onClose} />;
                }
                case Type.SERVICE_REMOVE_ACCEPTED:
                  {
                    return <NotificationRemoveAccepted key={notification.id} notification={notification} handleClose={onClose} />;
                  }
                case Type.REPAIR_REQUEST: {
                  return <RepairRequestNotification key={notification.id} notification={notification} handleClose={onClose} />;
                }

                default:
                  return null;
              }
            }
            )}
      </List>
    </Dialog>
  );
}

export default NotificationCenter;
