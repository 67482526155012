import * as React from "react"
import { Box, Button, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { dataTableActions } from "../../store/data-table";


import { getAcceptedAndRefundedServices, processService, servicesCalculation } from "../../utils/service-helpers"
import { formatCurrency } from "../../utils/currency";


const DueToPartner = ({ onClick }) => {
    const { t } = useTranslation();
    const currency = useSelector((state) => state.auth.currency);
    const partnersFilter = useSelector(state => state.dataTable.partnersFilter)
    const repairOrders = useSelector(state => state.dataTable.repairOrders)
    const [totalToBePaid, setTotalToBePaid] = React.useState(0);
    const [unpaidOrdersIds, setUnpaidOrdersIds] = React.useState([]);
    const unpaidCheck = useSelector(state => state.dataTable.dueToPartnerCheck)
    const dispatch = useDispatch();


    React.useEffect(() => {
        let totalToBePaid = 0;
        let orderIds = [];

        if (repairOrders && repairOrders.length > 0) {
            let repairOrdersFiltered = getRepairOrders(partnersFilter, repairOrders)
            repairOrdersFiltered = repairOrdersFiltered.filter(order => order.outsourcedToId !== null);


            totalToBePaid = repairOrdersFiltered.reduce((totalAmountUnpaid, order) => {
                if (order.outsourcedTo === null || !order.outsourcedTo?.services) {
                    return totalAmountUnpaid;
                }

                const acceptedAndRefundedServices = getAcceptedAndRefundedServices(order.outsourcedTo?.services);
                const acceptedAndRefundedServicesProcessed = acceptedAndRefundedServices.reduce((acc, service) => {
                    const processed = processService(service)
                    return acc += processed.unitPrice * processed.quantity - processed.discount;
                }, 0)

                let servicesSum = 0;
                let payments = 0;
                let invoice = null;
                if (order.outsourcedTo?.services) {
                    servicesSum = servicesCalculation(order.outsourcedTo.services).totalAccepted;
                    const hasResolution = order.outsourcedTo.resolutionTypeId !== null;
                    if (order.outsourcedTo?.repairPayments) {
                        payments = order.outsourcedTo.repairPayments.reduce((total, payment) => parseFloat(total) + parseFloat(payment.amount), 0);
                    }
                    const isDelivered = order.outsourcedTo.deliveredAt !== null;
                    if (isDelivered) {
                        if (payments != acceptedAndRefundedServicesProcessed) {
                            orderIds.push(order.id);
                            return totalAmountUnpaid + parseFloat(acceptedAndRefundedServicesProcessed) - parseFloat(payments);
                        }
                    }
                }
                return totalAmountUnpaid
            }, 0);
        }
        setTotalToBePaid(totalToBePaid);
        dispatch(dataTableActions.setDueToPartnerIds(orderIds));
    }, [repairOrders, partnersFilter])
    const handleUnpaidCheck = () => {
        dispatch(dataTableActions.toggleDueToPartner());
    }

    return <Stack alignItems={'center'}>
        {/* <Button variant={'outlined'} onClick={onClick}>
            Total unpaid ${totalToBePaid}npm
        </Button> */}
        <FormControlLabel control={<Checkbox checked={unpaidCheck} onChange={handleUnpaidCheck} />} label={`${t("Due to partners")} ${formatCurrency(totalToBePaid, currency)}`} />

    </Stack>
}

export default DueToPartner;

function getRepairOrders(partnersFilter, repairOrders) {
    return partnersFilter.length == 0 ? repairOrders : repairOrders.filter(order => {
        if (order.outsourcedFrom) {
            const match = partnersFilter.find(element => element.id == order.outsourcedFrom.organization.id);
            // console.log(match?.name);
            if (match) {
                return true;
            }
        }
        if (order.outsourcedTo) {
            const match = partnersFilter.find(element => element.id == order.outsourcedTo.organization.id);
            if (match) {
                return true;
            }
        }
        if (!order.outsourcedFrom && !order.outsourcedTo) {
            const ourOrgMatch = partnersFilter.find(element => element.id == order.organizationId);
            if (ourOrgMatch) {
                return true;
            }
        }
    });
}
