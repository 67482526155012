

export const repairStatuses =
{
    InQueue: { index: 1, name: 'In Queue', nameNoSpace: 'InQueue' },                                            // Item received, waiting to be diagnosed/repaired
    Diagnose: { index: 2, name: 'Diagnose', nameNoSpace: 'Diagnose' },                                           // Item is being diagnosed.
    NotifyCustomer: { index: 3, name: 'Notify Customer', nameNoSpace: 'NotifyCustomer' },                              // We have a solution, we just need to let the customer know
    // Accepted: { index: 4, name: 'Accepted', nameNoSpace: 'Accepted' },                                          // Customer accepted current solution
    OnHold: { index: 4, name: 'On Hold' },     // Customer is not decided
    InRepair: { index: 5, name: 'In Repair', nameNoSpace: 'InRepair' },                                          // The technicians are working on this item
    WaitingForParts: { index: 6, name: 'Waiting For Parts', nameNoSpace: 'WaitingForParts' },                           // Parts are out of stock, parts order was placed already.
    InDelivery: { index: 7, name: 'In Delivery', nameNoSpace: 'InDelivery' },                                      // Delivering to customer or partner
    QualityAssurance: { index: 8, name: 'Quality Assurance', nameNoSpace: 'QualityAssurance' },                          // Checking stuff assuring everything works as expected
    ReadyForPickUp: { index: 9, name: 'Ready For Pick Up', nameNoSpace: 'ReadyForPickUp' },                            // The item is on shelf waiting for customer to pick up
    DeliveredToCustomer: { index: 10, name: 'Delivered To Customer', nameNoSpace: 'DeliveredToCustomer' },                   // (Final status
    Request: { index: 11, name: 'Request', nameNoSpace: 'Request' },                                      // (FROM PARTNER / CUSTOMER) Item waiting for pick up from their location
    Delivered: { index: 12, name: 'Delivered', nameNoSpace: 'Delivered' },                                         // CASE WITHOUT RESOLUTION: *INPUT* FROM Partners or Customer. Arriver in our shop waiting to be put in quque
    OrderParts: { index: 13, name: 'Order Parts', nameNoSpace: 'OrderParts' },
    ReceivedFromPartner: { index: 14, name: 'Received From Partner', nameNoSpace: 'ReceivedFromPartner' },

    //                                                              // CASE WITH RESOLUTION: *OUTPUT*(FOR PARTNERS) they'll do their QA and arrange agordingly in store
}

export const repairStatusesId =
{
    1: { index: 1, name: 'In Queue', nameNoSpace: 'InQueue' },                                            // Item received, waiting to be diagnosed/repaired
    2: { index: 2, name: 'Diagnose', nameNoSpace: 'Diagnose' },                                           // Item is being diagnosed.
    3: { index: 3, name: 'Notify Customer', nameNoSpace: 'NotifyCustomer' },                              // We have a solution, we just need to let the customer know
    // Accepted: { index: 4, name: 'Accepted', nameNoSpace: 'Accepted' },                                          // Customer accepted current solution
    4: { index: 4, name: 'On Hold' },     // Customer is not decided
    5: { index: 5, name: 'In Repair', nameNoSpace: 'InRepair' },                                          // The technicians are working on this item
    6: { index: 6, name: 'Waiting For Parts', nameNoSpace: 'WaitingForParts' },                           // Parts are out of stock, parts order was placed already.
    7: { index: 7, name: 'In Delivery', nameNoSpace: 'InDelivery' },                                      // Delivering to customer or partner
    8: { index: 8, name: 'Quality Assurance', nameNoSpace: 'QualityAssurance' },                          // Checking stuff assuring everything works as expected
    9: { index: 9, name: 'Ready For Pick Up', nameNoSpace: 'ReadyForPickUp' },                            // The item is on shelf waiting for customer to pick up
    10: { index: 10, name: 'Delivered To Customer', nameNoSpace: 'DeliveredToCustomer' },                   // (Final status
    11: { index: 11, name: 'Request', nameNoSpace: 'Request' },                                      // (FROM PARTNER / CUSTOMER) Item waiting for pick up from their location
    12: { index: 12, name: 'Delivered', nameNoSpace: 'Delivered' },                                         // CASE WITHOUT RESOLUTION: *INPUT* FROM Partners or Customer. Arriver in our shop waiting to be put in quque
    13: { index: 13, name: 'Order Parts', nameNoSpace: 'OrderParts' },
    14: { index: 14, name: 'Received From Partner', nameNoSpace: 'ReceivedFromPartner' },

    //                                                              // CASE WITH RESOLUTION: *OUTPUT*(FOR PARTNERS) they'll do their QA and arrange agordingly in store
}

export const statusColors = {
    'In Queue': { color: 'inQueue', variant: 'contained', fontColor: 'black' },
    'Diagnose': { color: 'diagnose', variant: 'contained', fontColor: 'black' },
    'Notify Customer': { color: 'notifyCustomer', variant: 'contained' },
    'Accepted': { color: 'accepted', variant: 'contained' },
    'On Hold': { color: 'onHold', variant: 'contained' },
    'In Repair': { color: 'inRepair', variant: 'contained', },
    'Waiting For Parts': { color: 'waitingForParts', variant: 'contained' },
    'In Delivery': { color: 'inDelivery', variant: 'contained' },
    'Quality Assurance': { color: 'qualityAssurance', variant: 'contained' },
    'Ready For Pick Up': { color: 'readyForPickUp', variant: 'contained' },
    'Delivered To Customer': { color: 'deliveredToCustomer', variant: 'contained' },
    // 'Request': { color: 'inQueue', variant: 'outlined' },
    'Delivered': { color: 'delivered', variant: 'filled' },
    'Order Parts': { color: 'delivered', variant: 'filled' },

    'default': { color: 'darkBlue', variant: 'contained' },
}

export const repairResolutions =
{
    Repaired: { index: 1, name: 'Repaired', nameNoSpace: 'Repaired' },
    Declined: { index: 2, name: 'Declined', nameNoSpace: 'Declined' },
    Irreparable: { index: 3, name: 'Irreparable', nameNoSpace: 'Irreparable' },
    Irreproducible: { index: 4, name: 'Irreproducible', nameNoSpace: 'Irreproducible' },
    Warranty: { index: 5, name: 'Warranty', nameNoSpace: 'Warranty' }
}


