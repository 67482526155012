import * as React from 'react';
import { Chip, Stack, Box, IconButton, Button, Typography } from '@mui/material';
import axios from "../../axios-config";

import StatusChip from '../StatusChip/StatusChip';
import ResolutionChip from '../ResolutionChip/ResolutionChip';

import { useSelector, useDispatch } from 'react-redux';
import { dataTableActions } from "../../store/data-table";

import { useTranslation } from 'react-i18next';
import { processService } from '../../utils/service-helpers';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import { formatCurrency } from '../../utils/currency';


const StatusCell = ({ repairOrderData, onUpdateStatusCallback }) => {
    // console.log(repairOrderData);
    const currency = useSelector((state) => state.auth.currency);
    const [isHandlingRequest, setIsHandlingRequest] = React.useState(false);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const declineRequest = async (event) => {
        event.stopPropagation();
        try {
            const response = await axios.delete(`repair-orders/outsource/${repairOrderData.id}`)
            if (response.status == 200) {
                dispatch(dataTableActions.removeOrder(repairOrderData.id))
            }
        } catch (err) {
            console.log(err);
            // enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        };
    }

    const cancelRequest = async (event) => {
        event.stopPropagation();
        try {
            const response = await axios.delete(`repair-orders/outsource/${repairOrderData.id}`)

            if (response.status == 200) {
                dispatch(dataTableActions.setRepairOrderById(response.data));
            }
        } catch (err) {
            console.log(err);
            // enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        };
    }

    const acceptRequest = async (event) => {
        event.stopPropagation();
        try {
            setIsHandlingRequest(true);
            const response = await axios.put(`repair-orders/outsource/${repairOrderData.id}/accept`)
            if (response.status == 200) {
                dispatch(dataTableActions.removeOrder(repairOrderData.id))
                dispatch(dataTableActions.addRepairOrder(response.data));
            }
        } catch (err) {
            console.log(err);
            // enqueueSnackbar(`Something went wrong!`, { variant: "error" });
        }
        finally {
            setIsHandlingRequest(false);
        }
    }

    const services = repairOrderData.services;
    const acceptedAndRefundedServices = services?.filter(service => service.isAccepted || service.refundedServiceId);

    const acceptedAndRefundedServicesProcessed = acceptedAndRefundedServices?.reduce((acc, service) => {
        const processed = processService(service)
        return acc += processed.unitPrice * processed.quantity - processed.discount;
    }, 0)

    const totalPayments = repairOrderData.repairPayments?.reduce((acc, payment) => {
        return acc += parseFloat(payment.amount);
    }, 0)

    const PaidStatus = () => {
        if (repairOrderData.paid) {
            return <Chip color='accepted' variant='outlined' label={t("Paid")} icon={<PaymentsOutlinedIcon />} />
        }
        if (acceptedAndRefundedServicesProcessed == 0 && totalPayments == 0) {
            return null;
        }
        if (totalPayments == acceptedAndRefundedServicesProcessed) {
            return <Chip color='accepted' variant='outlined' label={t("Paid")} icon={<PaymentsOutlinedIcon />} />
            // return <Stack sx={{ border: '1px solid', borderColor: 'success.main', borderRadius: '20px', px: '10px', py: '3px' }} direction={'row'} alignItems={'center'} spacing={1}><PaymentsOutlinedIcon color='success' /> <Typography variant='body2'>{t("Paid")}</Typography></Stack>
        }
        return <Stack direction={'row'}><Typography variant='body2'>{formatCurrency(totalPayments, currency)}/{formatCurrency(acceptedAndRefundedServicesProcessed, currency)}</Typography> </Stack>
    }
    return (
        <Stack className='statusColumnCell' alignItems={'center'} justifyContent={'center'} height={'100%'}>
            <Stack spacing={1} alignItems={'center'}>
                {/* Add resolution chip */}
                {repairOrderData.resolutionType ? <>
                    <ResolutionChip variant={repairOrderData.resolutionType.name} />
                </>

                    : null}
                <StatusChip repairOrderData={repairOrderData} onUpdateStatusCallback={onUpdateStatusCallback} />
                {repairOrderData.resolutionType ? <>
                    <PaidStatus />
                </>
                    : null}

                {repairOrderData.repairOrderOutsourceRequest ? <Button size='small' color='red' variant='outlined' onClick={cancelRequest}> {t('Cancel')} </Button> : null}
                {repairOrderData.isRequest && !isHandlingRequest ? <Stack direction={'row'} spacing={1}>
                    <Button
                        size='small'
                        color='green'
                        variant='outlined'
                        onClick={acceptRequest}
                    >
                        {t('Accept')}
                    </Button>
                    <Button size='small' color='red' variant='outlined' onClick={declineRequest}> {t('Decline')} </Button></Stack> : null}
            </Stack>
        </Stack >
    )
}

export default React.memo(StatusCell);